import { colors, InsightPicker } from '@hazadapt-git/public-core-base'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Color from 'color'
import { FC, MouseEventHandler, useCallback, useState } from 'react'
import { IoEyedrop, IoEllipsisVertical, IoCheckmark } from 'react-icons/io5'
import { makeStyles } from 'tss-react/mui'
import { useWindowSizeUp } from '../../../lib/utils'
import { MapThemePickerModal } from '../MapThemePickerModal'

interface MapControlsProps {
    themeConfig: InsightPicker
    variantConfig: InsightPicker
    handleThemeChange(theme: string): void
    handleVariantChange(variant: string): void
    activeVariant: string
    activeTheme: string
    setActiveTheme(theme: string): void
}

export const MapControls: FC<MapControlsProps> = ({
    themeConfig,
    variantConfig,
    handleThemeChange,
    handleVariantChange,
    activeVariant,
    activeTheme,
    setActiveTheme,
}) => {
    const { classes: localClasses } = useLocalStyles()
    const lgScreens = useWindowSizeUp('lg')
    const [themePickerOpen, setThemePickerOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const mobileMenuOpen = Boolean(anchorEl)

    const handleMobileMenuOpen: MouseEventHandler<HTMLButtonElement> =
        useCallback((event) => {
            setAnchorEl(event.currentTarget)
        }, [])

    const handleMobileMenuClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    return (
        <>
            <div className={localClasses.mapControls}>
                {themeConfig && themeConfig.options.length > 0 && (
                    <Box
                        className={localClasses.iconContainer}
                        sx={{ borderBottomRightRadius: '0.5rem' }}
                    >
                        <IconButton onClick={() => setThemePickerOpen(true)}>
                            <IoEyedrop
                                color={colors.grays.NOIR}
                                size="1rem"
                                aria-label="Open theme picker"
                            />
                        </IconButton>
                    </Box>
                )}
                {variantConfig?.options.length > 0 && (
                    <>
                        {lgScreens ? (
                            <div className={localClasses.variantContainer}>
                                {variantConfig.options.map((o) => {
                                    const backgroundColor =
                                        o.buttonColor ?? colors.primary.CERULEAN
                                    const borderColor =
                                        o.value === activeVariant
                                            ? Color(
                                                  o.buttonColor ??
                                                      colors.primary.CERULEAN
                                              )
                                                  .lighten(0.9)
                                                  .toString()
                                            : o.buttonColor ??
                                              colors.primary.CERULEAN
                                    const hoverBackgroundColor = Color(
                                        o.buttonColor ?? colors.primary.CERULEAN
                                    )
                                        .darken(0.1)
                                        .toString()
                                    const hoverBorderColor =
                                        o.value === activeVariant
                                            ? borderColor
                                            : hoverBackgroundColor
                                    return (
                                        <Button
                                            key={o.value}
                                            onClick={() =>
                                                handleVariantChange(o.value)
                                            }
                                            className={
                                                localClasses.variantOption
                                            }
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor:
                                                        hoverBackgroundColor,
                                                    borderColor:
                                                        hoverBorderColor,
                                                },
                                                backgroundColor:
                                                    backgroundColor,
                                                borderColor: borderColor,
                                            }}
                                        >
                                            {o.label}
                                        </Button>
                                    )
                                })}
                            </div>
                        ) : (
                            <Box
                                className={localClasses.iconContainer}
                                sx={{
                                    borderBottomLeftRadius: '0.5rem',
                                    marginLeft: 'auto',
                                }}
                            >
                                <IconButton onClick={handleMobileMenuOpen}>
                                    <IoEllipsisVertical
                                        color={colors.grays.NOIR}
                                        size="1rem"
                                        aria-label="Open map options menu"
                                    />
                                </IconButton>
                                <Menu
                                    id="map-insight-menu"
                                    anchorEl={anchorEl}
                                    open={mobileMenuOpen}
                                    onClose={handleMobileMenuClose}
                                >
                                    {variantConfig.options.map((o) => (
                                        <MenuItem
                                            key={o.value}
                                            onClick={() =>
                                                handleVariantChange(o.value)
                                            }
                                        >
                                            {o.value === activeVariant ? (
                                                <>
                                                    <ListItemIcon>
                                                        <IoCheckmark />
                                                    </ListItemIcon>
                                                    <span>{o.label}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <ListItemText inset>
                                                        {o.label}
                                                    </ListItemText>
                                                </>
                                            )}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        )}
                    </>
                )}
            </div>
            <MapThemePickerModal
                open={themePickerOpen}
                onClose={() => setThemePickerOpen(false)}
                themeConfig={themeConfig}
                selectedTheme={activeTheme}
                setTheme={setActiveTheme}
                onSaveThemeChange={() => handleThemeChange(activeTheme)}
            />
        </>
    )
}

const useLocalStyles = makeStyles()({
    mapControls: {
        alignItems: 'flex-start',
        display: 'flex',
        pointerEvents: 'none',
        position: 'absolute',
        width: '100%',
        zIndex: 1,
    },
    iconContainer: {
        backgroundColor: colors.grays.BLANC,
        boxShadow: '0 0.25rem 0.5rem rgba(0,0,0,0.16)',
        padding: '.5rem',
        pointerEvents: 'all',
        top: 0,
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    },
    variantContainer: {
        display: 'flex',
        gap: '.5rem',
        marginLeft: 'auto',
        marginRight: '2.625rem',
        padding: '.5rem',
    },
    variantOption: {
        borderRadius: '.5rem',
        borderStyle: 'solid',
        borderWidth: 3,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        minWidth: '10rem',
        padding: '.5rem',
        pointerEvents: 'all',
    },
})
