import {
    NewRPUserDTO,
    OrganizationWithMembers,
    RPUser,
    colors,
    RPUserPermission,
    Organization,
} from '@hazadapt-git/public-core-base'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {
    ChangeEventHandler,
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IoAlertCircle,
    IoCheckmark,
    IoCreateOutline,
    IoInformationCircle,
    IoMap,
    IoOpenOutline,
    IoPencil,
    IoRefresh,
} from 'react-icons/io5'
import { MdAddPhotoAlternate } from 'react-icons/md'
import { makeStyles } from 'tss-react/mui'
import { TeamMemberList } from '../organisms/TeamMemberList'
import {
    AddTeamMemberModal,
    ConfirmOrganizationLogoModal,
    ViewJurisdictionModal,
} from '../organisms'
import { acceptedImageTypes, contactHazAdaptOutreach } from '../../lib/entities'
import {
    errorColor,
    primaryIconSize,
    successColor,
    theme,
} from '../../lib/styles/universal'
import {
    useWindowSizeUp,
    toBase64,
    checkUserPermissions,
    toast,
} from '../../lib/utils'
import { ReactCropperElement } from 'react-cropper'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { Picker, Popover } from '../atoms'
import { ITimezoneOption, useTimezoneSelect } from 'react-timezone-select'
import Box from '@mui/material/Box'
import classNames from 'classnames'

interface BaseTemplateProps {
    activeUser: RPUser
    organization: OrganizationWithMembers
    onSaveLogoClick(src: File): void
    onSubmitNewUser(user: Partial<RPUser>): void
    onResendInvite?(user: RPUser): void
    onRemoveUser(user: RPUser): void
    onTransferOwnership(user: RPUser): void
    onSaveUserChanges(user: RPUser): void
    pendingChanges?: boolean
    onSaveOrganizationChangesClick(
        organization: Partial<Organization>
    ): Promise<void>
}

interface SetupOrganizationPageTemplateProps {
    mode: 'setup'
}

interface ManageOrganizationPageTemplateProps {
    mode: 'manage'
    onGoToOrgChangelog?(): void
}

type OrganizationDetailsTemplateProps = BaseTemplateProps &
    (SetupOrganizationPageTemplateProps | ManageOrganizationPageTemplateProps)

const DEFAULT_NEW_USER: NewRPUserDTO = {
    first_name: '',
    last_name: '',
    email: '',
    permissions: [],
}

export const OrganizationDetailsTemplate: FC<
    OrganizationDetailsTemplateProps
> = (props: OrganizationDetailsTemplateProps) => {
    const { classes: localClasses } = useLocalStyles()
    const [jurisdictionOpen, setJurisdictionOpen] = useState<boolean>(false)
    const [logoDialogOpen, setLogoDialogOpen] = useState<boolean>(false)
    const [logoCropperImage, setLogoCropperImage] = useState('')
    const [newUser, setNewUser] = useState<NewRPUserDTO>(DEFAULT_NEW_USER)
    const [addMemberOpen, setAddMemberOpen] = useState<boolean>(false)
    const [selectedTimezone, setSelectedTimezone] = useState<ITimezoneOption>()
    const fileInputRef = useRef<HTMLInputElement>(null)
    const cropperRef = useRef<ReactCropperElement>(null)
    const mdScreens = useWindowSizeUp('md')
    const { options: timezones, parseTimezone } = useTimezoneSelect({
        labelStyle: 'abbrev',
    })
    const [errors, setErrors] = useState<
        Partial<Record<keyof Organization, string>>
    >({})
    const [isEditingTimeZone, setIsEditingTimeZone] = useState<boolean>(
        props.mode === 'setup'
    )

    useEffect(() => {
        setSelectedTimezone(
            timezones.find((tz) => tz.value === props.organization.timezone)
        )
    }, [timezones, props.organization.timezone])

    const hasEditControls = checkUserPermissions(props.activeUser, [
        RPUserPermission.EDIT_ORGANIZATION,
    ])

    const onAddMemberClose = useCallback(() => {
        setAddMemberOpen(false)
        setNewUser(DEFAULT_NEW_USER)
    }, [])

    const submitNewUser = (newUser: NewRPUserDTO) => {
        props.onSubmitNewUser(newUser)
        onAddMemberClose()
    }

    const handleFileInputChange: ChangeEventHandler<HTMLInputElement> =
        useCallback(async (e) => {
            if (!e.target.files || e.target.files.length === 0) {
                return
            }
            const file = e.target.files[0]
            const fileStr = await toBase64(file)
            if (!fileStr) return
            setLogoCropperImage(fileStr)
            setLogoDialogOpen(true)
        }, [])

    const handleInputButtonClick = useCallback(() => {
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }, [])

    const onSaveLogoClick = () => {
        cropperRef.current?.cropper
            ?.getCroppedCanvas({ fillColor: 'white' })
            .toBlob((blob) => {
                if (!blob) return
                const file = new File([blob], `${props.organization.name}`, {
                    type: 'image/jpeg',
                })
                props.onSaveLogoClick(file)
            }, 'image/jpeg')
        const data = cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
        setLogoCropperImage(data || logoCropperImage)
        setLogoDialogOpen(false)
    }

    const onCancelClick = useCallback(() => {
        setLogoDialogOpen(false)
        setLogoCropperImage(props.organization.logo?.url || '')
    }, [props.organization.logo])

    const updateNewUser = useCallback((changes: Partial<NewRPUserDTO>) => {
        setNewUser((user) => ({
            ...user,
            ...changes,
        }))
    }, [])

    const handleChangeTimezone = useCallback(
        (val: string) => {
            const errorsObj = { ...errors }
            delete errorsObj.timezone
            setSelectedTimezone(parseTimezone(val))
            setErrors(errorsObj)
        },
        [errors, parseTimezone]
    )

    const onSaveOrganizationChangesClick = () => {
        const errorsObj = { ...errors }
        if (!selectedTimezone) errorsObj.timezone = 'Time zone is required'
        if (
            props.mode === 'manage' &&
            props.organization.timezone === selectedTimezone?.value
        ) {
            toast(
                'Changes saved!',
                <IoCheckmark size={primaryIconSize} color={successColor} />
            )
            setIsEditingTimeZone(false)
        } else if (Object.keys(errorsObj).length > 0) {
            toast(
                'Please resolve all errors before saving.',
                <IoAlertCircle color={errorColor} size={primaryIconSize} />
            )
            setErrors(errorsObj)
        } else {
            props.onSaveOrganizationChangesClick({
                timezone: selectedTimezone?.value,
            })
            setIsEditingTimeZone(false)
        }
    }

    const onDiscardOrganizationChangesClick = () => {
        if (props.organization.timezone !== selectedTimezone?.value) {
            setSelectedTimezone(
                timezones.find((tz) => tz.value === props.organization.timezone)
            )
        }
        setIsEditingTimeZone(false)
    }

    return (
        <Grid
            container
            gap="1rem"
            className={classNames(localClasses.container, {
                [localClasses.setupContainer]: props.mode === 'setup',
            })}
        >
            <Grid item xs={12} className={localClasses.organization}>
                <Typography
                    variant={mdScreens ? 'h2' : 'h3'}
                    component="h1"
                    color={colors.grays.NOIR}
                >
                    {props.mode === 'manage'
                        ? 'Organization Settings'
                        : `Welcome to ResiliencePoint! 🎉`}
                </Typography>
                {props.mode === 'setup' && (
                    <Typography>
                        Finish setting up your organization to get started
                        tracking your community's resilience!
                    </Typography>
                )}
            </Grid>
            {props.organization.logo ? (
                <Grid item xs="auto">
                    <div className={localClasses.logoContainer}>
                        <img
                            src={
                                logoCropperImage || props.organization.logo?.url
                            }
                            alt={props.organization.logo?.alt}
                            className={localClasses.logo}
                        />
                        <Button
                            variant="contained"
                            onClick={handleInputButtonClick}
                            className={localClasses.editButton}
                        >
                            <IoPencil
                                className={localClasses.pencil}
                                style={{
                                    color: `${colors.grays.BLANC}`,
                                }}
                                aria-label="Upload new organization logo"
                            />
                        </Button>
                    </div>
                </Grid>
            ) : (
                <Grid item alignSelf="center">
                    <Button
                        variant="outlined"
                        className={localClasses.topButton}
                        onClick={handleInputButtonClick}
                    >
                        <MdAddPhotoAlternate
                            size="3rem"
                            color={colors.grays.THUNDERCLOUD}
                        />
                        Add Organization Logo*
                    </Button>
                </Grid>
            )}
            <Grid
                item
                alignSelf="flex-start"
                display="grid"
                gridTemplateColumns={{
                    xs: '1fr',
                    lg: '2fr 1fr',
                    xl: 'repeat(2, 1fr)',
                }}
                alignItems="flex-start"
                gap="1rem"
                md
                width={{ xs: '100%', lg: 'fit-content' }}
            >
                <Stack gap="1rem">
                    <Typography
                        variant={mdScreens ? 'h3' : 'h4'}
                        display="flex"
                        alignItems="center"
                    >
                        {props.organization.name}
                        {hasEditControls && (
                            <Popover
                                icon={<IoInformationCircle />}
                                helpText={
                                    <span>
                                        To change the name of your organization
                                        on ResiliencePoint,{' '}
                                        <Link
                                            href={`${contactHazAdaptOutreach}?subject=${encodeURI(
                                                `${props.organization.name} - RP Name Change Request`
                                            )}`}
                                            target="_blank"
                                        >
                                            contact HazAdapt. <IoOpenOutline />
                                        </Link>{' '}
                                    </span>
                                }
                            />
                        )}
                    </Typography>
                    {hasEditControls && isEditingTimeZone ? (
                        <Box
                            display="flex"
                            flexDirection={{ xs: 'column', xl: 'row' }}
                            alignItems="flex-start"
                            gap="0.5rem"
                        >
                            <Picker
                                id="organization-timezone"
                                inputLabel="Organization time zone"
                                label="Organization time zone"
                                data={timezones}
                                value={selectedTimezone?.value}
                                placeholder="Select your organization's time zone"
                                onChange={handleChangeTimezone}
                                subtext="If your jurisdiction spans more than one time zone, please select the timezone your organization's primary offices are located in."
                                error={errors.timezone}
                                required={props.mode === 'setup'}
                            />
                            {props.mode === 'manage' ? (
                                <div className={localClasses.changesCtas}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={onSaveOrganizationChangesClick}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        onClick={
                                            onDiscardOrganizationChangesClick
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            ) : null}
                        </Box>
                    ) : hasEditControls ? (
                        <Box
                            display="flex"
                            flexDirection={{ xs: 'column', sm: 'row' }}
                            alignItems={{ xs: 'flex-start', sm: 'center' }}
                            gap="0.25rem"
                        >
                            <Typography fontWeight={500}>
                                Organization Time Zone:
                            </Typography>
                            <Link
                                component="button"
                                onClick={() => setIsEditingTimeZone(true)}
                            >
                                {selectedTimezone?.label ??
                                    'No time zone selected'}
                                <IoCreateOutline size={primaryIconSize} />
                            </Link>
                        </Box>
                    ) : (
                        <Typography>
                            <Typography component="span" fontWeight={500}>
                                Organization Time Zone:
                            </Typography>{' '}
                            {selectedTimezone?.label ?? 'No timezone selected'}
                        </Typography>
                    )}
                </Stack>
                <Box
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row', lg: 'column' }}
                    gap="0.5rem"
                    alignItems={{ xs: 'stretch', lg: 'flex-end' }}
                >
                    <Button
                        variant="outlined"
                        className={localClasses.topButton}
                        onClick={() => setJurisdictionOpen(true)}
                    >
                        <IoMap size="2rem" color={colors.grays.THUNDERCLOUD} />
                        View Jurisdiction Map
                    </Button>
                    {props.mode === 'manage' && hasEditControls ? (
                        <Button
                            variant="outlined"
                            className={localClasses.topButton}
                            onClick={props.onGoToOrgChangelog}
                        >
                            <IoRefresh
                                size="2rem"
                                color={colors.grays.THUNDERCLOUD}
                            />
                            View Change History
                        </Button>
                    ) : null}
                </Box>
                <input
                    type="file"
                    accept={acceptedImageTypes}
                    ref={fileInputRef}
                    hidden
                    onChange={handleFileInputChange}
                />
                <ConfirmOrganizationLogoModal
                    open={logoDialogOpen}
                    title="Confirm Logo"
                    selectedImage={logoCropperImage}
                    onImageChange={setLogoCropperImage}
                    onCancelClick={onCancelClick}
                    onSaveClick={onSaveLogoClick}
                    onClose={onCancelClick}
                    ref={cropperRef}
                />
                <ViewJurisdictionModal
                    open={jurisdictionOpen}
                    onClose={() => setJurisdictionOpen(false)}
                    jurisdiction={props.organization.jurisdiction}
                />
            </Grid>
            <Grid item xs={12}>
                <TeamMemberList
                    members={props.organization.members}
                    seats={props.organization.seats}
                    onAddUserClick={() => setAddMemberOpen(true)}
                    mode={props.mode}
                    onResendInvite={props.onResendInvite}
                    onRemoveUser={props.onRemoveUser}
                    activeUser={props.activeUser}
                    onSaveUserChanges={props.onSaveUserChanges}
                    onTransferOwnership={props.onTransferOwnership}
                />
                <AddTeamMemberModal
                    activeUser={props.activeUser}
                    open={addMemberOpen}
                    onClose={onAddMemberClose}
                    onSubmit={submitNewUser}
                    newUser={newUser}
                    updateNewUser={updateNewUser}
                />
            </Grid>
            {props.mode === 'setup' ? (
                <Grid item xs={12} className={localClasses.ctas}>
                    <Button
                        variant="contained"
                        onClick={onSaveOrganizationChangesClick}
                    >
                        Continue
                    </Button>
                </Grid>
            ) : null}
        </Grid>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        margin: '0 auto',
        width: '100%',
    },
    setupContainer: {
        padding: '1rem',
        maxWidth: theme.breakpoints.values.xl,
    },
    organization: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        flexGrow: 1,
    },
    pencil: {
        height: '1rem',
        width: '1rem',
        zIndex: 2,
    },
    ctas: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1.5rem',
        justifyContent: 'flex-end',
        padding: '1rem',
    },
    topButton: {
        gap: '1rem',
        flex: 1,
        width: '100%',
        padding: '1rem',
        color: colors.grays.NOIR,
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            height: '5rem',
            maxWidth: '20rem',
            whiteSpace: 'nowrap',
        },
        borderRadius: '1rem',
        borderColor: colors.grays.THUNDERCLOUD,
    },
    sub: {
        gap: '2rem',
        display: 'flex',
        flexDirection: 'column',
    },
    topButtons: {
        gap: '2rem',
        display: 'flex',
        flexDirection: 'column',
    },
    editButton: {
        position: 'absolute',
        height: '2rem',
        width: '2rem',
        boxShadow: '0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1)',
        padding: 0,
        minWidth: '2rem',
        right: 0,
        bottom: '0.5rem',
        zIndex: 1,
        [theme.breakpoints.up('md')]: {
            right: '0.5rem',
            bottom: '1rem',
        },
    },
    logo: {
        width: 'auto',
        minWidth: '8rem',
        height: '8rem',
        objectFit: 'contain',
        [theme.breakpoints.up('md')]: {
            height: '10rem',
            minWidth: '10rem',
        },
        [theme.breakpoints.up('lg')]: {
            height: '12rem',
        },
    },
    logoContainer: {
        position: 'relative',
        width: 'fit-content',
    },
    changesCtas: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        [theme.breakpoints.up('lg')]: {
            margin: '0.375rem 0',
        },
    },
})
