import Button from '@mui/material/Button'
import { FC } from 'react'
import { Dialog } from '../atoms'
import { RPPaymentMethod } from '../../lib/entities'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { PaymentMethodDetails } from './PaymentMethodDetails'
import { makeStyles } from 'tss-react/mui'
import { theme, useStyles } from '../../lib/styles/universal'
import classNames from 'classnames'
import { NewPaymentMethodButton } from './NewPaymentMethodButton'

interface ChangePaymentMethodModalProps {
    open?: boolean
    title: string
    subcopy?: string
    onCancel?(): void
    onSubmit: React.MouseEventHandler
    methods: RPPaymentMethod[]
    selectedMethod?: RPPaymentMethod
    onSelect(pm: RPPaymentMethod): void
    onCreateNewMethod?: React.MouseEventHandler
}

export const ChangePaymentMethodModal: FC<ChangePaymentMethodModalProps> = ({
    open = false,
    title,
    subcopy,
    onCancel,
    onSubmit,
    methods,
    selectedMethod,
    onSelect,
    onCreateNewMethod,
}) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            title={title}
            content={
                <Stack gap="1rem">
                    {subcopy && <Typography>{subcopy}</Typography>}
                    <div className={localClasses.paymentMethods}>
                        {methods.map((pm) => (
                            <div
                                key={pm.id}
                                className={classNames(classes.paymentMethod, {
                                    [classes.selectedPaymentMethod]:
                                        selectedMethod?.id === pm.id,
                                })}
                                onClick={() => onSelect(pm)}
                            >
                                <PaymentMethodDetails {...pm} hideDefaultTag />
                            </div>
                        ))}
                        {onCreateNewMethod && (
                            <NewPaymentMethodButton
                                onClick={onCreateNewMethod}
                                className={classes.paymentMethod}
                            />
                        )}
                    </div>
                </Stack>
            }
            ctas={[
                <Button
                    key="cancel"
                    onClick={onCancel}
                    variant="outlined"
                    color="secondary"
                >
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    onClick={onSubmit}
                    variant="contained"
                    color="primary"
                    disabled={!selectedMethod}
                >
                    Continue
                </Button>,
            ]}
        />
    )
}

const useLocalStyles = makeStyles()({
    paymentMethods: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        gap: '1rem',
    },
})
