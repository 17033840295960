import { FC, MouseEventHandler } from 'react'
import { SimplifiedSeatSubscription } from '../../lib/hooks/useBilling'
import { Dialog } from '../atoms'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

interface RestoreSeatSubscriptionModalProps {
    subscription: SimplifiedSeatSubscription
    onClose(): void
    onConfirmRestore: MouseEventHandler
}

export const RestoreSeatSubscriptionModal: FC<
    RestoreSeatSubscriptionModalProps
> = ({ subscription, onClose, onConfirmRestore }) => {
    return (
        <Dialog
            open
            title="Restore Seats Subscription"
            content={
                <Stack gap="1rem">
                    <Typography>
                        Are you sure you want to restore this seats
                        subscription?
                    </Typography>
                    <Typography>
                        {subscription.seatCount} seat
                        {subscription.seatCount === 1 ? '' : 's'} will be added
                        back to your organization, and the subscription will
                        renew for ${subscription.renewalAmount} on{' '}
                        {subscription.renewalDate}.
                    </Typography>
                </Stack>
            }
            onClose={onClose}
            ctas={[
                <Button
                    key="cancel"
                    variant="outlined"
                    color="secondary"
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                <Button key="confirm" onClick={onConfirmRestore}>
                    Restore Subscription
                </Button>,
            ]}
        />
    )
}
