import { FC, useCallback, useMemo, useState } from 'react'
import { SubscriptionStatus } from '@hazadapt-git/public-core-base'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useWindowSizeUp } from '../../lib/utils'
import { SeatSubscriptionView } from '../molecules'
import {
    SimplifiedSeatSubscription,
    useBilling,
} from '../../lib/hooks/useBilling'
import { RestoreSeatSubscriptionModal } from '../molecules/RestoreSeatSubscriptionModal'

interface SeatSubscriptionTableProps {
    onNavigateToCancelSeats(sub: SimplifiedSeatSubscription): void
    onRestoreSubscription(sub: SimplifiedSeatSubscription): void
}

export const SeatSubscriptionTable: FC<SeatSubscriptionTableProps> = ({
    onNavigateToCancelSeats,
    onRestoreSubscription,
}) => {
    const lgScreen = useWindowSizeUp('lg')
    const { simplifiedSeatSubscriptions: seatSubs } = useBilling()
    const [subscriptionToRestore, setSubscriptionToRestore] =
        useState<SimplifiedSeatSubscription>()

    const sortedSeatSubs = useMemo(() => {
        const subs = [...seatSubs]
        return subs.sort((a, b) => {
            if (a.status === SubscriptionStatus.CANCELLED) return 1
            if (b.status === SubscriptionStatus.CANCELLED) return -1
            return 0
        })
    }, [seatSubs])

    const handleRestoreSubscription = useCallback(
        (sub: SimplifiedSeatSubscription) => {
            setSubscriptionToRestore(sub)
        },
        []
    )

    const handleCloseConfirmRestoreSubscriptionModal = useCallback(() => {
        setSubscriptionToRestore(undefined)
    }, [])

    const confirmRestoreSubscription = useCallback(() => {
        if (!subscriptionToRestore) return
        onRestoreSubscription(subscriptionToRestore)
        setSubscriptionToRestore(undefined)
    }, [onRestoreSubscription, subscriptionToRestore])

    const columns = lgScreen
        ? ['Purchase Date', 'Number of Seats', 'Renewal Date']
        : ['Number of Seats', 'Renewal Date']

    return (
        <>
            {sortedSeatSubs.length > 0 && (
                <TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map((col, i) => (
                                    <TableCell
                                        key={col
                                            .toLowerCase()
                                            .replaceAll(' ', '-')}
                                    >
                                        <Typography
                                            textAlign={
                                                i > 0 ? 'center' : 'left'
                                            }
                                            fontWeight={500}
                                        >
                                            {col}
                                        </Typography>
                                    </TableCell>
                                ))}
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedSeatSubs.map((s) => (
                                <SeatSubscriptionView
                                    key={`seat-sub-${s.id}`}
                                    seatSub={s}
                                    onNavigateToCancelSeats={
                                        onNavigateToCancelSeats
                                    }
                                    onRestoreSubscription={
                                        handleRestoreSubscription
                                    }
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {subscriptionToRestore ? (
                <RestoreSeatSubscriptionModal
                    subscription={subscriptionToRestore}
                    onClose={handleCloseConfirmRestoreSubscriptionModal}
                    onConfirmRestore={confirmRestoreSubscription}
                />
            ) : null}
        </>
    )
}
