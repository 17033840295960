import Button from '@mui/material/Button'
import { FC } from 'react'
import { Dialog } from '../atoms'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

interface RestoreCoreSubscriptionModalProps {
    open?: boolean
    onCancel?(): void
    onConfirm: React.MouseEventHandler
    expirationDate: string
}

export const RestoreCoreSubscriptionModal: FC<
    RestoreCoreSubscriptionModalProps
> = ({ open = false, onCancel, onConfirm, expirationDate }) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            title="Restore ResiliencePoint Subscription"
            content={
                <Stack gap="1rem">
                    <Typography>
                        Your organization's access to ResiliencePoint was set to
                        expire on {expirationDate}. By restoring your
                        ResiliencePoint subscription, your organization's
                        subscription renewal schedule will be resumed. The next
                        invoice will be due {expirationDate}.
                    </Typography>
                </Stack>
            }
            ctas={[
                <Button
                    key="cancel"
                    onClick={onCancel}
                    variant="outlined"
                    color="secondary"
                >
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    onClick={onConfirm}
                    variant="contained"
                    color="primary"
                >
                    Confirm & Restore
                </Button>,
            ]}
        />
    )
}
