import { FC } from 'react'
import { Navigate } from 'react-router'
import { NamespacedPageProps } from '../../../lib/entities'
import { LoadingView } from '../../molecules'

interface ActiveUserAndOrgWalledPageProps extends NamespacedPageProps {
    Page: FC<NamespacedPageProps>
}

export const ActiveUserAndOrgWalledPage: FC<
    ActiveUserAndOrgWalledPageProps
> = ({ Page, ...props }) => {
    if (props.loading) return <LoadingView />
    if (!props.user || !props.organization) return null
    if (props.user.status === 'invited')
        return <Navigate replace to="/setup/profile" />
    if (props.organization.status === 'invited')
        return <Navigate replace to="/setup/organization" />
    return <Page {...props} />
}
