import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
    updateSubscriptionPaymentMethod,
    useWindowSizeUp,
} from '../../lib/utils'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { colors, SubscriptionStatus } from '@hazadapt-git/public-core-base'
import {
    IoChevronDown,
    IoChevronUp,
    IoClose,
    IoEllipsisVertical,
    IoReload,
    IoWallet,
} from 'react-icons/io5'
import { errorColor, primaryIconSize } from '../../lib/styles/universal'
import {
    SimplifiedSeatSubscription,
    useBilling,
} from '../../lib/hooks/useBilling'
import Menu from '@mui/material/Menu'
import { CustomMenuItem } from '../../lib/entities/misc'
import { useNavigate } from 'react-router'
import { ChangePaymentMethodModal } from './ChangePaymentMethodModal'
import { RPPaymentMethod } from '../../lib/entities'

interface SeatSubscriptionViewProps {
    seatSub: SimplifiedSeatSubscription
    onNavigateToCancelSeats(sub: SimplifiedSeatSubscription): void
    onRestoreSubscription(sub: SimplifiedSeatSubscription): void
}

export const SeatSubscriptionView: FC<SeatSubscriptionViewProps> = ({
    seatSub,
    onNavigateToCancelSeats,
    onRestoreSubscription,
}) => {
    const lgScreen = useWindowSizeUp('lg')
    const navigate = useNavigate()
    const { paymentMethods } = useBilling()
    const [selectedMethod, setSelectedMethod] = useState<RPPaymentMethod>()
    const [expanded, setExpanded] = useState<boolean>(false)
    const [showChangePaymentMethodModal, setShowChangePaymentMethodModal] =
        useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)

    const onOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const onOptionsClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        setExpanded(false)
    }, [lgScreen])

    useEffect(() => {
        const method = paymentMethods.find(
            (pm) => pm.id === seatSub.paymentMethod
        )
        setSelectedMethod(method)
    }, [seatSub, paymentMethods, showChangePaymentMethodModal])

    const handleChangePaymentMethodClick = () => {
        setShowChangePaymentMethodModal(true)
    }

    const handleUpdatePaymentMethod = useCallback(async () => {
        if (!selectedMethod) return
        await updateSubscriptionPaymentMethod(seatSub.id, selectedMethod)
    }, [seatSub, selectedMethod])

    const handleCreateNewPaymentMethod = useCallback(() => {
        navigate(
            `/settings/subscriptions/payment-methods/new?subscription_id=${seatSub.id}`
        )
    }, [navigate, seatSub])

    const closeChangePaymentMethodModal = () => {
        setShowChangePaymentMethodModal(false)
    }

    const sortedPaymentMethods = useMemo(() => {
        const methods = [...paymentMethods]
        return methods.sort((a, b) => {
            if (a.id === seatSub.paymentMethod) return -1
            if (b.id === seatSub.paymentMethod) return 1
            return 0
        })
    }, [paymentMethods, seatSub])

    const Chevron = expanded ? IoChevronUp : IoChevronDown

    return (
        <>
            <TableRow
                sx={{
                    backgroundColor:
                        seatSub.status !== SubscriptionStatus.CANCELLED
                            ? colors.grays.BLANC
                            : colors.grays.SILK,
                    ...(lgScreen
                        ? {}
                        : {
                              '& td': {
                                  borderBottom: 'unset',
                              },
                          }),
                }}
            >
                {/* Purchase date */}
                {lgScreen && (
                    <TableCell>
                        <Typography>{seatSub.purchaseDate}</Typography>
                    </TableCell>
                )}

                {/* Seat count */}
                <TableCell>
                    <Typography textAlign={lgScreen ? 'center' : 'left'}>
                        {seatSub.seatCount}
                    </Typography>
                </TableCell>

                {/* Renewal date */}
                <TableCell>
                    <Typography textAlign={'center'}>
                        {seatSub.status === SubscriptionStatus.ACTIVE
                            ? seatSub.renewalDate
                            : seatSub.status ===
                              SubscriptionStatus.PENDING_CANCELLATION
                            ? `Ending on ${seatSub.cancelDate}`
                            : `Canceled on ${seatSub.cancelDate}`}
                    </Typography>
                </TableCell>

                {/* Actions */}
                <TableCell
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                    }}
                >
                    {lgScreen ? (
                        <>
                            {seatSub.status !== SubscriptionStatus.CANCELLED ? (
                                <>
                                    <IconButton onClick={onOptionsClick}>
                                        <IoEllipsisVertical
                                            size={primaryIconSize}
                                            color={colors.grays.NOIR}
                                            aria-label="Open subscription options menu"
                                        />
                                    </IconButton>
                                    <Menu
                                        id="subscription-options"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={onOptionsClose}
                                    >
                                        {seatSub.status ===
                                        SubscriptionStatus.ACTIVE ? (
                                            <>
                                                <CustomMenuItem
                                                    label="Change Payment Method"
                                                    icon={
                                                        <IoWallet
                                                            size={
                                                                primaryIconSize
                                                            }
                                                        />
                                                    }
                                                    onClick={
                                                        handleChangePaymentMethodClick
                                                    }
                                                />
                                                <CustomMenuItem
                                                    label="Cancel Subscription"
                                                    danger
                                                    icon={
                                                        <IoClose
                                                            size={
                                                                primaryIconSize
                                                            }
                                                            color={errorColor}
                                                        />
                                                    }
                                                    onClick={() =>
                                                        onNavigateToCancelSeats(
                                                            seatSub
                                                        )
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <CustomMenuItem
                                                label="Restore Subscription"
                                                icon={
                                                    <IoReload
                                                        size={primaryIconSize}
                                                    />
                                                }
                                                onClick={() =>
                                                    onRestoreSubscription(
                                                        seatSub
                                                    )
                                                }
                                            />
                                        )}
                                    </Menu>
                                </>
                            ) : null}
                        </>
                    ) : (
                        <IconButton onClick={() => setExpanded((ex) => !ex)}>
                            <Chevron
                                size={primaryIconSize}
                                color={colors.primary.CERULEAN}
                            />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>
            {!lgScreen && (
                <TableRow
                    sx={{
                        backgroundColor:
                            seatSub.status !== SubscriptionStatus.CANCELLED
                                ? colors.grays.BLANC
                                : colors.grays.SILK,
                    }}
                >
                    <TableCell sx={{ py: 0 }} colSpan={12}>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <Stack my="1rem" gap="1rem" alignItems="flex-start">
                                <Typography>
                                    <Typography
                                        fontWeight={500}
                                        component="span"
                                    >
                                        Purchase date:{' '}
                                    </Typography>
                                    {seatSub.purchaseDate}
                                </Typography>
                                {seatSub.status ===
                                SubscriptionStatus.ACTIVE ? (
                                    <>
                                        <Button
                                            variant="outlined"
                                            onClick={
                                                handleChangePaymentMethodClick
                                            }
                                        >
                                            Change Payment Method
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={() =>
                                                onNavigateToCancelSeats(seatSub)
                                            }
                                        >
                                            Cancel Subscription
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Typography>
                                            <Typography
                                                fontWeight={500}
                                                component="span"
                                            >
                                                {seatSub.status ===
                                                SubscriptionStatus.PENDING_CANCELLATION
                                                    ? 'Ending on: '
                                                    : 'Canceled on: '}
                                            </Typography>
                                            {seatSub.cancelDate}
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            onClick={() =>
                                                onRestoreSubscription(seatSub)
                                            }
                                        >
                                            Restore Subscription
                                        </Button>
                                    </>
                                )}
                            </Stack>
                        </Collapse>
                    </TableCell>
                    <ChangePaymentMethodModal
                        open={showChangePaymentMethodModal}
                        title="Change Payment Method"
                        subcopy="Select an existing payment method or add a new one."
                        onCancel={closeChangePaymentMethodModal}
                        onSubmit={handleUpdatePaymentMethod}
                        onCreateNewMethod={handleCreateNewPaymentMethod}
                        methods={sortedPaymentMethods}
                        selectedMethod={selectedMethod}
                        onSelect={setSelectedMethod}
                    />
                </TableRow>
            )}
        </>
    )
}
