import { RPUser, RPUserPermission } from '@hazadapt-git/public-core-base'
import { FC } from 'react'
import { Checkbox } from '../atoms'
import Grid from '@mui/material/Grid'
import { Field, useFormikContext } from 'formik'
import Divider from '@mui/material/Divider'
import { makeStyles } from 'tss-react/mui'
import { isUserAdmin } from '../../lib/utils'

interface PermissionsListProps {
    selectedPermissions: RPUserPermission[]
    mode?: 'form' | null
    disabled?: boolean
    user?: RPUser
    activeUser: RPUser
}

// OWNER and ADMIN are controlled on their own and not in PermissionsList
export const permissionsMap: Map<RPUserPermission, string> = new Map<
    RPUserPermission,
    string
>([
    [RPUserPermission.ADD_MEMBER, 'Add Member to Organization'],
    [RPUserPermission.EDIT_MEMBER, 'Edit Team Members'],
    [RPUserPermission.DELETE_MEMBER, 'Delete Member from Organization'],
    [RPUserPermission.EDIT_ORGANIZATION, 'Edit Organization'],
    // [RPUserPermission.MANAGE_BILLING, 'Manage Billing'],
    // [RPUserPermission.PAUSE_MEMBER_ACCESS, 'Pause Member Access'],
])

export const PermissionsList: FC<PermissionsListProps> = (
    props: PermissionsListProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const allPermissions = Array.from(permissionsMap.keys())
    const { setFieldValue } = useFormikContext()

    const adminClick = () => {
        let newPermissions = [...props.selectedPermissions]
        if (!props.selectedPermissions.includes(RPUserPermission.ADMIN)) {
            newPermissions = [
                ...newPermissions,
                ...allPermissions.filter((p) => !newPermissions.includes(p)),
                RPUserPermission.ADMIN,
            ]
        } else {
            newPermissions = newPermissions.filter(
                (p) => p !== RPUserPermission.ADMIN
            )
        }
        setFieldValue('permissions', newPermissions)
    }
    const handlePermissionChange =
        (permission: RPUserPermission) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            let newPermissions = [...props.selectedPermissions]
            if (event.target.checked) {
                newPermissions.push(permission)
            } else {
                newPermissions = newPermissions.filter((p) => p !== permission)
                if (
                    props.selectedPermissions.includes(RPUserPermission.ADMIN)
                ) {
                    newPermissions = newPermissions.filter(
                        (p) => p !== RPUserPermission.ADMIN
                    )
                }
            }

            const allSelected = allPermissions.every((p) =>
                newPermissions.includes(p)
            )
            if (
                allSelected &&
                !newPermissions.includes(RPUserPermission.ADMIN) &&
                isUserAdmin(props.activeUser)
            ) {
                newPermissions.push(RPUserPermission.ADMIN)
            }
            setFieldValue('permissions', newPermissions)
        }
    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <label>
                    <Field
                        as={Checkbox}
                        type="checkbox"
                        name="permissions"
                        value={RPUserPermission.ADMIN}
                        disabled={
                            props.disabled || !isUserAdmin(props.activeUser)
                        }
                        checked={props.selectedPermissions.includes(
                            RPUserPermission.ADMIN
                        )}
                        onChange={adminClick}
                    />
                    Administrator
                </label>
                <Divider
                    sx={{
                        margin: '0.5rem 0',
                        width: '100%',
                    }}
                />
            </Grid>

            {/* Fake permissions checkboxes to show what controls all users have */}
            <Grid item xs={12} sm={6} lg={4}>
                <label className={localClasses.checkboxWrapper}>
                    <Checkbox disabled defaultChecked label={null} />
                    View Insights
                </label>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <label className={localClasses.checkboxWrapper}>
                    <Checkbox disabled defaultChecked label={null} />
                    Query for Insights
                </label>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <label className={localClasses.checkboxWrapper}>
                    <Checkbox disabled defaultChecked label={null} />
                    Export Insights
                </label>
            </Grid>

            {Array.from(permissionsMap).map(([permission, label]) => (
                <Grid item key={permission} xs={12} sm={6} lg={4}>
                    <label className={localClasses.checkboxWrapper}>
                        <Field
                            as={Checkbox}
                            type="checkbox"
                            name="permissions"
                            value={permission}
                            checked={props.selectedPermissions.includes(
                                permission
                            )}
                            onChange={handlePermissionChange(permission)}
                        />
                        {label}
                    </label>
                </Grid>
            ))}
        </Grid>
    )
}

const useLocalStyles = makeStyles()({
    checkboxWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
})
