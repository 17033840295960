import { registerables, Chart } from 'chart.js'
import React, {
    ChangeEventHandler,
    FC,
    Suspense,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { Toaster } from 'react-hot-toast'
import { useLocation, Navigate, useNavigate } from 'react-router'
import { Routes, Route, RoutesProps, RouteProps } from 'react-router-dom'
import {
    RootState,
    useAppDispatch,
    useAppSelector,
    useAppStore,
} from '../lib/store'
import {
    getOrganization,
    getUser,
    toBase64,
    useWindowSizeUp,
    updateProfilePicture,
    getSubscriptions,
    isUserAdmin,
    isUserOwner,
    useResponsiveStyles,
} from '../lib/utils'
import {
    setUser,
    setOrganization,
    updateUserHasAccess,
    setDesktopMenuOpen,
} from '../lib/slices/base'
import {
    ActiveUserAndOrgWalledPage,
    ActiveUserWalledPage,
    ConfirmProfilePictureModal,
    DesktopSiteNav,
    LoadingView,
    MobileSiteNav,
    ScrollToTop,
    SiteFooter,
    SiteHeader,
    UnresolvedSeatsModal,
} from '../components'
import { ErrorMessage, FeaturePackage } from '@hazadapt-git/public-core-base'
import { primaryIconSize, useStyles } from '../lib/styles/universal'
import { NavBarItem, acceptedImageTypes } from '../lib/entities'
import { ReactCropperElement } from 'react-cropper'
import classNames from 'classnames'
import { CheckoutSuccessPage } from '../pages/CheckoutSuccess'
import {
    getHazardData,
    getPrepCheckData,
    getTrendingHazards,
} from '../lib/utils/cms'

import {
    IoHomeOutline as HomeIcon,
    IoSettingsOutline as SettingsIcon,
} from 'react-icons/io5'
import { MdLocationOn } from 'react-icons/md'
import HazardGuideIcon from '../assets/images/material-symbols_book-outline.svg'
import PrepCheckIcon from '../assets/images/ph_shield-bold.svg'
import { MdOutlineHistory as SavedQueriesIcon } from 'react-icons/md'
import { SurveyAnswerInsights } from '../pages/SurveyAnswerInsight'
import { useOrganization } from '../lib/hooks/useOrganization'
import { shallowEqual } from 'react-redux'

// Page imports
const HomePage = React.lazy(() => import('../pages/Home'))
const HazardGuideInsights = React.lazy(
    () => import('../pages/HazardGuideInsights')
)
const LocalSafetyInformation = React.lazy(
    () => import('../pages/LocalSafetyInformation')
)
const PrepCheckInsights = React.lazy(() => import('../pages/PrepCheckInsights'))
const CheckoutPageWrapper = React.lazy(() => import('../pages/Checkout'))
const SavedQueriesPage = React.lazy(() => import('../pages/SavedQueries'))
const SetupOrganizationPage = React.lazy(
    () => import('../pages/SetupOrganization')
)
const SetupProfilePage = React.lazy(() => import('../pages/SetupProfile'))
const SetupWizardWelcomePage = React.lazy(
    () => import('../pages/SetupWizardWelcome')
)
const SplashPage = React.lazy(() => import('../pages/Splash'))
const SettingsPage = React.lazy(() => import('../pages/Settings'))
const NotInOrganization = React.lazy(() => import('../pages/NotInOrganization'))
const PausedOrganizationPage = React.lazy(
    () => import('../pages/PausedOrganization')
)
const DeactivatedOrganizationPage = React.lazy(
    () => import('../pages/DeactivatedOrganization')
)

Chart.register(...registerables)
Chart.defaults.font.family = 'Rubik'

export const OrgRouter: FC<RoutesProps> = (props: RoutesProps) => {
    const location = useLocation()
    const store = useAppStore()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { classes } = useStyles()
    const mdScreens = useWindowSizeUp('md')

    const profilePictureInputRef = useRef<HTMLInputElement>(null)
    const profilePictureCropperRef = useRef<ReactCropperElement>(null)

    const [loading, setLoading] = useState<boolean>(true)
    const [profilePictureModalOpen, setProfilePictureModalOpen] =
        useState<boolean>(false)
    const [newProfilePicture, setNewProfilePicture] = useState<string>()
    const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)
    const [unresolvedSeatsModalOpen, setUnresolvedSeatsModalOpen] =
        useState<boolean>(false)

    const user = useAppSelector(
        (state: RootState) => state.base.user,
        shallowEqual
    )
    const organization = useAppSelector(
        (state: RootState) => state.base.organization,
        shallowEqual
    )
    const userHasAccess = useAppSelector(
        (state: RootState) => state.base.userHasAccess
    )
    const desktopMenuOpen = useAppSelector(
        (state: RootState) => state.base.desktopMenuOpen
    )
    const hasLocalContent = useAppSelector(
        (state: RootState) => state.content.has_local_content
    )

    const { unresolvedSeatCount } = useOrganization()
    const responsiveStyles = useResponsiveStyles()

    const isAdminOrOwner = isUserOwner(user) || isUserAdmin(user)
    const currentLocation = location.pathname

    useEffect(() => {
        if (isAdminOrOwner && unresolvedSeatCount > 0) {
            setUnresolvedSeatsModalOpen(true)
        }
    }, [isAdminOrOwner, unresolvedSeatCount])

    useEffect(() => {
        if (
            isAdminOrOwner &&
            unresolvedSeatCount > 0 &&
            !['/settings', '/checkout'].some((route) =>
                currentLocation.startsWith(route)
            )
        ) {
            setUnresolvedSeatsModalOpen(true)
        }
    }, [isAdminOrOwner, unresolvedSeatCount, currentLocation])

    useEffect(() => {
        if (mdScreens) {
            setMobileMenuOpen(false)
        } else {
            dispatch(setDesktopMenuOpen(true))
        }
    }, [mdScreens, dispatch])

    useEffect(() => {
        const userPromise = getUser()
            .then((user) => {
                dispatch(setUser(user))
                dispatch(updateUserHasAccess(true))
            })
            .catch((err) => {
                console.error(err)
                dispatch(setUser(null))
                if (
                    err.response?.data?.error ===
                    ErrorMessage.RP_UNAUTHORIZED_USER
                ) {
                    dispatch(updateUserHasAccess(false))
                }
            })
        const orgPromise = getOrganization()
            .then((org) => {
                dispatch(setOrganization(org))
            })
            .catch((err) => {
                console.error(err)
                dispatch(setOrganization(null))
            })
        const subPromise = getSubscriptions()
        const trendingHazardsPromise = getTrendingHazards()
        const hazardDataPromise = getHazardData()
        const prepCheckDataPromise = getPrepCheckData()
        Promise.all([
            userPromise,
            orgPromise,
            subPromise,
            trendingHazardsPromise,
            hazardDataPromise,
            prepCheckDataPromise,
        ]).finally(() => {
            setLoading(false)
        })
    }, [dispatch, store])

    useEffect(() => {
        if (!currentLocation.startsWith('/settings') || !user) {
            setProfilePictureModalOpen(false)
        }
    }, [user, currentLocation])

    useEffect(() => {
        setNewProfilePicture(user?.profile_picture_uri ?? undefined)
    }, [user])

    const navItems: NavBarItem[] = useMemo(
        () =>
            user?.status !== 'active' || organization?.status !== 'active'
                ? []
                : [
                      {
                          label: 'Home',
                          path: '/home',
                          icon: <HomeIcon size={primaryIconSize} />,
                      },
                      {
                          label: 'Hazard Guide Activity',
                          path: '/insights/hazard-guide',
                          icon: (
                              <img
                                  src={HazardGuideIcon}
                                  alt="Hazard Guide Icon"
                                  style={{
                                      height: primaryIconSize,
                                      width: primaryIconSize,
                                  }}
                              />
                          ),
                      },
                      {
                          label: 'Local Safety Information',
                          path: '/insights/local-safety-information',
                          hide: !hasLocalContent,
                          icon: <MdLocationOn size={primaryIconSize} />,
                      },
                      {
                          label: 'Prep Check Activity',
                          path: '/insights/prep-checks',
                          hide:
                              organization.base_subscription ===
                              FeaturePackage.CONTENT_PROVIDER,
                          icon: (
                              <img
                                  src={PrepCheckIcon}
                                  alt="Prep Check Icon"
                                  style={{
                                      height: primaryIconSize,
                                      width: primaryIconSize,
                                  }}
                              />
                          ),
                      },
                      {
                          label: 'Saved Queries',
                          path: '/saved-queries',
                          icon: <SavedQueriesIcon size={primaryIconSize} />,
                      },
                      {
                          label: 'Settings',
                          path: '/settings',
                          icon: <SettingsIcon size={primaryIconSize} />,
                      },
                  ],
        [user, organization, hasLocalContent]
    )

    const onLogoPress = () => {
        if (currentLocation !== '/') {
            window.location.href = '/'
        } else {
            navigate('/', { replace: true })
        }
    }

    const handleProfilePictureUpload: ChangeEventHandler<
        HTMLInputElement
    > = async (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }
        const file = e.target.files[0]
        const fileStr = await toBase64(file)
        if (!fileStr) return
        setNewProfilePicture(fileStr)
        setProfilePictureModalOpen(true)
    }

    const saveProfilePictureChange = () => {
        if (!user) return
        profilePictureCropperRef.current?.cropper
            .getCroppedCanvas({ fillColor: 'white' })
            .toBlob((blob) => {
                if (!blob) return
                const file = new File([blob], `${user.rp_user_id}`)
                updateProfilePicture(file)
                setProfilePictureModalOpen(false)
            })
    }

    const onCloseProfilePictureModal = () => {
        setProfilePictureModalOpen(false)
        setNewProfilePicture(user?.profile_picture_uri ?? undefined)
    }

    const routes: RouteProps[] = useMemo(() => {
        let routesToSupport: RouteProps[] = []
        if (!user || user.status === 'invited') {
            routesToSupport.push({
                path: '/join/:inviteCode',
                element: (
                    <SetupWizardWelcomePage user={user} loading={loading} />
                ),
            })
        }
        if (
            user &&
            organization &&
            ['active', 'invited'].includes(user.status) &&
            ['active', 'invited'].includes(organization.status)
        ) {
            routesToSupport.push(
                {
                    path: '/home',
                    element: (
                        <ActiveUserAndOrgWalledPage
                            Page={HomePage}
                            user={user}
                            organization={organization}
                            loading={loading}
                        />
                    ),
                },
                {
                    path: '/insights/hazard-guide',
                    element: (
                        <ActiveUserAndOrgWalledPage
                            Page={HazardGuideInsights}
                            user={user}
                            organization={organization}
                            loading={loading}
                        />
                    ),
                },
                {
                    path: '/checkout',
                    element: (
                        <ActiveUserWalledPage
                            Page={CheckoutPageWrapper}
                            loading={loading}
                            user={user}
                            organization={organization}
                        />
                    ),
                },
                {
                    path: '/checkout/success',
                    element: (
                        <ActiveUserWalledPage
                            Page={CheckoutSuccessPage}
                            loading={loading}
                            user={user}
                            organization={organization}
                        />
                    ),
                },
                {
                    path: '/saved-queries',
                    element: (
                        <ActiveUserAndOrgWalledPage
                            Page={SavedQueriesPage}
                            user={user}
                            organization={organization}
                            loading={loading}
                        />
                    ),
                },
                {
                    path: '/settings/*',
                    element: (
                        <ActiveUserAndOrgWalledPage
                            Page={SettingsPage}
                            user={user}
                            organization={organization}
                            loading={loading}
                        />
                    ),
                },
                {
                    path: '/setup/profile',
                    element:
                        organization && user.status === 'invited' ? (
                            <SetupProfilePage
                                loading={loading}
                                user={user}
                                organization={organization}
                            />
                        ) : (
                            <Navigate replace to="/home" />
                        ),
                },
                {
                    path: '/setup/organization',
                    element:
                        user.owner && organization.status === 'invited' ? (
                            <ActiveUserWalledPage
                                Page={SetupOrganizationPage}
                                user={user}
                                organization={organization}
                                loading={loading}
                            />
                        ) : (
                            <Navigate replace to="/home" />
                        ),
                },
                // Catch-all; keep this as the last route in the array
                {
                    path: '*',
                    element: <Navigate replace to="/home" />,
                }
            )
            if (hasLocalContent) {
                routesToSupport.push({
                    path: '/insights/local-safety-information',
                    element: (
                        <ActiveUserAndOrgWalledPage
                            Page={LocalSafetyInformation}
                            user={user}
                            organization={organization}
                            loading={loading}
                        />
                    ),
                })
            }
            if (
                organization?.base_subscription !==
                FeaturePackage.CONTENT_PROVIDER
            ) {
                routesToSupport.push(
                    {
                        path: '/insights/prep-checks',
                        element: (
                            <ActiveUserAndOrgWalledPage
                                Page={PrepCheckInsights}
                                user={user}
                                organization={organization}
                                loading={loading}
                            />
                        ),
                    },
                    {
                        path: `/insights/prep-checks/*`,
                        element: (
                            <ActiveUserAndOrgWalledPage
                                Page={SurveyAnswerInsights}
                                user={user}
                                organization={organization}
                                loading={loading}
                            />
                        ),
                    }
                )
            }
        } else if (organization?.status === 'paused') {
            routesToSupport.push(
                {
                    path: '/',
                    element: (
                        <PausedOrganizationPage
                            loading={loading}
                            organization={organization}
                        />
                    ),
                },
                {
                    path: '*',
                    element: <Navigate replace to="/" />,
                }
            )
        } else if (organization?.status === 'deactivated') {
            routesToSupport.push(
                {
                    path: '/',
                    element: (
                        <DeactivatedOrganizationPage
                            loading={loading}
                            organization={organization}
                        />
                    ),
                },
                {
                    path: '*',
                    element: <Navigate replace to="/" />,
                }
            )
        } else if (!userHasAccess) {
            routesToSupport.push(
                {
                    path: '/',
                    element: <NotInOrganization loading={loading} />,
                },
                {
                    path: '*',
                    element: <Navigate replace to="/" />,
                }
            )
        } else {
            routesToSupport.push(
                {
                    path: '/',
                    element: <SplashPage loading={loading} />,
                },
                {
                    path: '*',
                    element: <Navigate replace to="/" />,
                }
            )
        }
        return routesToSupport
    }, [hasLocalContent, loading, organization, user, userHasAccess])

    const noActiveUserOrOrg =
        user?.status !== 'active' || organization?.status !== 'active'

    return (
        <ScrollToTop>
            <div
                style={{
                    display: 'flex',
                    minHeight: '100vh',
                }}
            >
                {noActiveUserOrOrg ? null : mdScreens ? (
                    <DesktopSiteNav
                        user={user ?? undefined}
                        onToggleMenu={() =>
                            dispatch(setDesktopMenuOpen(!desktopMenuOpen))
                        }
                        onLogoPress={onLogoPress}
                        navItems={navItems}
                    />
                ) : (
                    <MobileSiteNav
                        user={user ?? undefined}
                        onLogoPress={onLogoPress}
                        navItems={navItems}
                        onToggleMenu={() => setMobileMenuOpen(!mobileMenuOpen)}
                        mobileMenuOpen={mobileMenuOpen}
                    />
                )}
                <div
                    className={classes.root}
                    style={
                        !noActiveUserOrOrg && mdScreens
                            ? responsiveStyles
                            : undefined
                    }
                >
                    {(noActiveUserOrOrg || !mdScreens) && (
                        <SiteHeader
                            user={user ?? undefined}
                            onLogoPress={onLogoPress}
                            onToggleMenu={() =>
                                setMobileMenuOpen(!mobileMenuOpen)
                            }
                            hideHamburgerMenu={noActiveUserOrOrg}
                        />
                    )}
                    <div
                        className={classNames(classes.page, {
                            [classes.settingsPage]:
                                currentLocation.startsWith('/settings'),
                        })}
                    >
                        <div
                            style={{
                                flex: 1,
                                width: '100%',
                                display: 'flex',
                            }}
                        >
                            {loading ? (
                                <LoadingView />
                            ) : (
                                <Suspense fallback={<LoadingView />}>
                                    <Routes {...props}>
                                        {routes.map((route: RouteProps) => (
                                            <Route
                                                key={route.path}
                                                {...route}
                                            />
                                        ))}
                                    </Routes>
                                </Suspense>
                            )}
                        </div>
                        <UnresolvedSeatsModal
                            open={unresolvedSeatsModalOpen}
                            setOpen={setUnresolvedSeatsModalOpen}
                            unresolvedSeatCount={unresolvedSeatCount}
                        />
                        <ConfirmProfilePictureModal
                            open={profilePictureModalOpen}
                            selectedImage={newProfilePicture}
                            onImageChange={setNewProfilePicture}
                            onSaveClick={saveProfilePictureChange}
                            onClose={onCloseProfilePictureModal}
                            ref={profilePictureCropperRef}
                        />
                        <input
                            hidden
                            ref={profilePictureInputRef}
                            type="file"
                            accept={acceptedImageTypes}
                            onChange={handleProfilePictureUpload}
                        />
                    </div>
                    {(noActiveUserOrOrg || !mdScreens) && <SiteFooter />}
                    <Toaster
                        toastOptions={{
                            success: { duration: 3000 },
                            error: { duration: 5000 },
                        }}
                        position="top-center"
                    />
                </div>
            </div>
        </ScrollToTop>
    )
}
