import {
    colors,
    OrganizationWithMembers,
    RPUser,
} from '@hazadapt-git/public-core-base'
import { useCallback, useEffect, useMemo } from 'react'
import {
    getFullOrganization,
    inviteUser,
    removeUser,
    resendInvitation,
    toast,
    transferOrgOwnership,
    updateOrganization,
    updateOrganizationLogo,
    updateUser,
} from '../utils'
import {
    errorColor,
    infoToastColor,
    primaryIconSize,
    successColor,
} from '../styles/universal'
import {
    IoAlertCircle,
    IoCheckmark,
    IoCheckmarkCircle,
    IoCloudUploadOutline,
    IoTrash,
} from 'react-icons/io5'
import { AxiosError } from 'axios'
import { NewRPUser } from '../entities'
import { useAppDispatch, useAppSelector } from '../store'
import { setFullOrganization as setOrganization } from '../slices/base'

export const useOrganization = () => {
    const dispatch = useAppDispatch()
    const organization = useAppSelector((state) => state.base.fullOrganization)

    useEffect(() => {
        getFullOrganization()
            .then((org) => {
                dispatch(setOrganization(org))
            })
            .catch((err) => {
                console.error(err)
                dispatch(setOrganization(null))
            })
    }, [dispatch])

    const handleSaveLogo = useCallback(
        async (file: File) => {
            if (!organization) return
            try {
                const updatedOrg = await updateOrganizationLogo(file)
                dispatch(setOrganization(updatedOrg))
            } catch (err) {
                console.error(err)
            }
        },
        [dispatch, organization]
    )

    const handleSubmitNewUser = useCallback(
        async (newUser: NewRPUser) => {
            if (!organization) return
            inviteUser([newUser])
                .then((members) => {
                    toast(
                        `${newUser.first_name} ${newUser.last_name} has been invited to your organization.`,
                        <IoCheckmarkCircle
                            color={successColor}
                            size={primaryIconSize}
                        />
                    )
                    const org = { ...organization }
                    dispatch(
                        setOrganization({
                            ...org,
                            members,
                        })
                    )
                })
                .catch((err) => {
                    console.error(err)
                    toast(
                        `There was a problem inviting ${newUser.first_name} ${newUser.last_name} to your organization. Please try again.`,
                        <IoAlertCircle
                            color={errorColor}
                            size={primaryIconSize}
                        />
                    )
                })
        },
        [dispatch, organization]
    )

    const handleRemoveUser = useCallback(
        async (user: RPUser) => {
            if (!organization) return
            try {
                toast(
                    `Removing ${user.first_name} ${user.last_name}...`,
                    <IoTrash color={infoToastColor} size={primaryIconSize} />
                )
                const orgWithUpdatedMemberList = await removeUser([
                    user.rp_user_id,
                ])
                setOrganization(orgWithUpdatedMemberList)
                toast(
                    `${user.first_name} ${user.last_name} has been removed from your organization.`,
                    <IoCheckmarkCircle
                        color={successColor}
                        size={primaryIconSize}
                    />
                )
            } catch (e) {
                toast(
                    `There was a problem removing ${user.first_name} ${user.last_name} from your organization. Please try again.`,
                    <IoAlertCircle color={errorColor} size={primaryIconSize} />
                )
            }
        },
        [organization]
    )

    const handleTransferOwnership = useCallback(
        async (user: RPUser) => {
            if (!organization) return
            try {
                const newMemberList = await transferOrgOwnership(
                    user.rp_user_id
                )
                if (newMemberList) {
                    const org = { ...organization }
                    dispatch(
                        setOrganization({
                            ...org,
                            members: newMemberList,
                        })
                    )
                    toast(
                        `${user.first_name} ${user.last_name} is now the owner of this space!`,
                        <IoCheckmarkCircle
                            color={successColor}
                            size={primaryIconSize}
                        />
                    )
                }
            } catch (err) {
                const error = err as AxiosError
                console.error(error)
                toast(
                    error.message ||
                        'Unable to transfer ownership. Please try again.',
                    <IoAlertCircle color={errorColor} size={primaryIconSize} />
                )
            }
        },
        [dispatch, organization]
    )

    const handleResendInvite = useCallback(
        async (user: RPUser) => {
            if (!organization) return
            if (user.status === 'active') {
                toast(
                    `${user.first_name} ${user.last_name} has already joined the organization.`
                )
                return
            }
            if (user.status !== 'invited') return
            try {
                const members = await resendInvitation(user.rp_user_id)
                const org = { ...organization }
                dispatch(
                    setOrganization({
                        ...org,
                        members,
                    })
                )
                toast(
                    `${user.first_name} ${user.last_name} has been re-sent an invitation to your organization.`,
                    <IoCheckmarkCircle
                        color={successColor}
                        size={primaryIconSize}
                    />
                )
            } catch (err) {
                console.error(err)
                toast(
                    `There was a problem re-sending the invitation to ${user.first_name} ${user.last_name}. Please try again.`,
                    <IoAlertCircle color={errorColor} size={primaryIconSize} />
                )
            }
        },
        [dispatch, organization]
    )

    const handleSaveOrganizationChanges = useCallback(
        async (organization: OrganizationWithMembers) => {
            toast(
                'Saving...',
                <IoCloudUploadOutline
                    color={colors.primary.BLUEBERRY}
                    size={primaryIconSize}
                />
            )
            try {
                const updatedOrg = await updateOrganization(organization)
                setOrganization(updatedOrg)
                toast(
                    'Changes saved!',
                    <IoCheckmark size={primaryIconSize} color={successColor} />
                )
            } catch (err) {
                const error = err as AxiosError
                console.error(error)
                toast(
                    error.message,
                    <IoAlertCircle size={primaryIconSize} color={errorColor} />
                )
            }
        },
        []
    )

    const handleSaveUserChanges = useCallback(
        async (user: RPUser) => {
            if (!organization) return
            const members = [...organization.members]
            const idx = members.findIndex(
                (m) => m.rp_user_id === user.rp_user_id
            )
            if (idx < 0) return
            members[idx] = {
                ...members[idx],
                ...user,
            }
            const updatedMembers = await updateUser(user)
            dispatch(
                setOrganization({ ...organization, members: updatedMembers })
            )
        },
        [dispatch, organization]
    )

    const unresolvedSeatCount = useMemo(() => {
        if (!organization) return 0
        return Math.max(organization.members.length - organization.seats, 0)
    }, [organization])

    return {
        organization,
        setOrganization,
        handleSaveLogo,
        handleRemoveUser,
        handleTransferOwnership,
        handleResendInvite,
        handleSaveUserChanges,
        handleSubmitNewUser,
        handleSaveOrganizationChanges,
        unresolvedSeatCount,
    }
}
