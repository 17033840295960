import { colors, InsightPicker } from '@hazadapt-git/public-core-base'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import NightShelterOutlinedIcon from '@mui/icons-material/NightShelterOutlined'
import HailIcon from '@mui/icons-material/Hail'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { FC, ReactNode } from 'react'
import { useWindowSizeUp } from '../../../lib/utils'
import { prepCheckColor } from '../../../lib/styles/universal'
import { ToggleButtons } from '../ToggleButtons'
import { IoContract, IoExpand } from 'react-icons/io5'
import { makeStyles } from 'tss-react/mui'

interface MapHeaderProps {
    placement_id: string
    headline: ReactNode
    mode?: InsightPicker
    handleModeChange(mode: string): void
    mapExpanded?: boolean
    expandOrCollapseMap(): void
}

export const MapHeader: FC<MapHeaderProps> = ({
    placement_id,
    headline,
    mode,
    handleModeChange,
    mapExpanded,
    expandOrCollapseMap,
}) => {
    const lgScreens = useWindowSizeUp('lg')
    const { classes: localClasses } = useLocalStyles()
    return (
        <div
            className={localClasses.header}
            style={{
                backgroundColor: placement_id.includes('prep-check')
                    ? prepCheckColor
                    : '#1A638F',
            }}
        >
            <Stack
                direction={lgScreens ? 'row' : 'column'}
                gap={lgScreens ? '1rem' : '.5rem'}
            >
                <Typography
                    variant="h4"
                    className={localClasses.headerText}
                    component="p"
                >
                    {headline}
                </Typography>
                {/* MODE SELECTOR */}
                {mode && (
                    <ToggleButtons
                        ariaLabel={`Toggle Between ${mode.options[0].label} and ${mode.options[1].label} Map Data`}
                        leftLabel={mode.options[0].label}
                        rightLabel={mode.options[1].label}
                        leftIcon={<NightShelterOutlinedIcon />}
                        rightIcon={<HailIcon />}
                        onLeftClick={() =>
                            handleModeChange(mode.options[0].value)
                        }
                        onRightClick={() =>
                            handleModeChange(mode.options[1].value)
                        }
                    />
                )}
            </Stack>
            {/* EXPAND ICON */}
            <Tooltip
                title={`${mapExpanded ? 'Shrink' : 'Expand'} map`}
                placement="top"
                arrow
            >
                <IconButton
                    onClick={expandOrCollapseMap}
                    sx={{
                        padding: 0,
                        color: colors.grays.BLANC,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    {mapExpanded ? <IoContract /> : <IoExpand />}
                </IconButton>
            </Tooltip>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem',
    },
    headerText: {
        alignItems: 'center',
        color: colors.grays.BLANC,
        display: 'flex',
        flexWrap: 'wrap',
        whiteSpace: 'break-spaces',
    },
})
