import React, { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { Dialog } from '../atoms'
import Button from '@mui/material/Button'
import { useAppSelector } from '../../lib/store'
import { getAvailableAddons, getCart } from '../../lib/utils'
import { useNavigate } from 'react-router'
import { useCart } from '../../lib/hooks/useCart'
import { shallowEqual } from 'react-redux'

interface UnresolvedSeatsModalProps {
    unresolvedSeatCount: number
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
}

export const UnresolvedSeatsModal: FC<UnresolvedSeatsModalProps> = ({
    unresolvedSeatCount,
    open,
    setOpen,
}) => {
    const navigate = useNavigate()
    const addons = useAppSelector((state) => state.payment.addons, shallowEqual)
    const { handleCartUpdate, handleSubmitCart } = useCart()
    const seatAddon = addons.find(
        (item) => item.product_id === 'prod_PvxheWanug2C7W'
    )

    useEffect(() => {
        getCart()
        getAvailableAddons()
    }, [])

    const handleRePurchaseSeats = async () => {
        setOpen(false)
        if (seatAddon) await handleCartUpdate(seatAddon, unresolvedSeatCount)
        await handleSubmitCart()
    }

    const handleNavigateToRemoveMembers = () => {
        setOpen(false)
        navigate('/settings/organization')
    }

    return (
        <Dialog
            open={open}
            title="Attention Needed - Organization Seats"
            content={`You have more members in your organization than available seats. You must either remove ${unresolvedSeatCount} team member${
                unresolvedSeatCount === 1 ? '' : 's'
            } from ResiliencePoint or re-purchase those seats to continue using ResiliencePoint.`}
            ctas={[
                <Button key="re-purchase-seats" onClick={handleRePurchaseSeats}>
                    Re-Purchase Seats
                </Button>,
                <Button
                    key="remove-members"
                    onClick={handleNavigateToRemoveMembers}
                    variant="outlined"
                    color="error"
                >
                    Select Members to Remove
                </Button>,
            ]}
        />
    )
}
