import React, { FC } from 'react'
import { Dialog } from '../atoms'
import Button from '@mui/material/Button'

interface UnsavedProfileChangesModalProps {
    open: boolean
    onClose(): void
    discardChanges(): void
    saveChanges(): void
}

export const UnsavedProfileChangesModal: FC<UnsavedProfileChangesModalProps> = (
    props: UnsavedProfileChangesModalProps
) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            title="Looks like you've got some unsaved changes"
            content={
                "Please select whether you'd like to save your changes or discard them before navigating away from this page."
            }
            ctas={[
                <Button
                    key={'discard-changes'}
                    onClick={() => {
                        props.discardChanges()
                        props.onClose()
                    }}
                    variant="outlined"
                    color="secondary"
                >
                    Discard Changes
                </Button>,
                <Button
                    key={'save-changes'}
                    onClick={() => {
                        props.saveChanges()
                        props.onClose()
                    }}
                    variant="contained"
                    color="primary"
                >
                    Save Changes
                </Button>,
            ]}
        />
    )
}
