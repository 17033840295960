import Grid from '@mui/material/Grid'
import MuiTextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { OutlinedTextFieldProps } from '@mui/material'
import React, { FC } from 'react'
import { colors } from '@hazadapt-git/public-core-base'
import { errorColor } from '../../lib/styles/universal'

interface TextFieldProps
    extends Omit<OutlinedTextFieldProps, 'variant' | 'error'> {
    icon?: React.ReactNode
    subtext?: React.ReactNode
    error?: boolean | string
}

export const TextField: FC<TextFieldProps> = (props: TextFieldProps) => (
    <Stack gap="0.5rem">
        <Grid
            container
            spacing="1rem"
            alignItems="center"
            justifyContent="center"
        >
            {props.label && (
                <Grid item xs={12}>
                    <Typography variant="h4">{props.label}</Typography>
                </Grid>
            )}
            {props.icon && (
                <Grid
                    item
                    xs={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    {props.icon}
                </Grid>
            )}
            <Grid item xs={props.icon ? 11 : 12}>
                <MuiTextField
                    {...props}
                    error={!!props.error}
                    label={undefined}
                    fullWidth
                />
            </Grid>
        </Grid>
        {props.error && (
            <Typography color={errorColor}>{props.error}</Typography>
        )}
        {props.subtext && (
            <Typography color={colors.grays.THUNDERCLOUD} variant="body2">
                {props.subtext}
            </Typography>
        )}
    </Stack>
)
