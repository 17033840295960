import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/system'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { customBorderRadius, theme } from '../../lib/styles/universal'
import { colors, OrganizationWithMembers } from '@hazadapt-git/public-core-base'
import { IoOpenOutline } from 'react-icons/io5'
import { useWindowSizeUp } from '../../lib/utils'
import {
    SimplifiedSeatSubscription,
    useBilling,
} from '../../lib/hooks/useBilling'
import { makeStyles } from 'tss-react/mui'
import { SeatSubscriptionTable } from '../organisms/SeatSubscriptionTable'

interface ManageSubscriptionsTemplateProps {
    page: 'manage' | 'cancel' | 'success'
    organization: OrganizationWithMembers
    onNavigateToCancelSeats(sub: SimplifiedSeatSubscription): void
    onRestoreSeatsSubscription(sub: SimplifiedSeatSubscription): void
    checked: boolean
    setChecked: Dispatch<SetStateAction<boolean>>
    subscriptionType: 'core' | 'seat'
    selectedSubscription?: SimplifiedSeatSubscription
}

export const ManageSubscriptionsTemplate: FC<
    ManageSubscriptionsTemplateProps
> = ({
    page,
    organization,
    onNavigateToCancelSeats,
    onRestoreSeatsSubscription,
    checked,
    setChecked,
    subscriptionType,
    selectedSubscription,
}) => {
    const { classes: localClasses } = useLocalStyles()
    const mdScreen = useWindowSizeUp('md')
    const { rootSubscriptionEndDate } = useBilling()

    return (
        <>
            {page === 'manage' && (
                <>
                    {/* Current seat tally */}
                    {organization && organization.seats > 0 ? (
                        <Typography
                            className={localClasses.mdBoldText}
                            component={'span'}
                        >
                            You are currently using{' '}
                            <Typography
                                fontSize={'inherit'}
                                fontWeight={700}
                                component={'span'}
                            >
                                {organization.members.length} out of{' '}
                                {organization.seats}
                            </Typography>{' '}
                            seats
                        </Typography>
                    ) : (
                        <Typography
                            className={localClasses.mdBoldText}
                            component={'span'}
                        >
                            You currently have no seats
                        </Typography>
                    )}
                    <SeatSubscriptionTable
                        onNavigateToCancelSeats={onNavigateToCancelSeats}
                        onRestoreSubscription={onRestoreSeatsSubscription}
                    />
                </>
            )}
            {page === 'cancel' && (
                <Stack gap={'2rem'} maxWidth={'55rem'}>
                    <Typography className={localClasses.mdBoldText}>
                        We're sorry to hear that you'd like to cancel your
                        ResiliencePoint{' '}
                        {subscriptionType === 'seat' && 'Seats '}subscription.
                    </Typography>
                    <Typography
                        variant={mdScreen ? 'body1' : 'body2'}
                        fontWeight={500}
                    >
                        After the final day of this subscription,{' '}
                        {subscriptionType === 'seat'
                            ? `you will no longer have access to the canceled seats. Please ensure that you've removed any users from any seats you wish to cancel${
                                  selectedSubscription?.cancelDate
                                      ? ` before the subscription ends on ${selectedSubscription.cancelDate}`
                                      : ''
                              }.`
                            : "all of your organization's local safety information, including hazard guides, will be removed from HazAdapt."}
                    </Typography>
                    {subscriptionType === 'core' && (
                        <Typography
                            variant={mdScreen ? 'body1' : 'body2'}
                            fontWeight={500}
                        >
                            Please be aware that once your subscription ends,
                            you will no longer have access to delete or make
                            changes to this information.
                        </Typography>
                    )}
                    {/* Contact us */}
                    <Stack
                        gap={'1rem'}
                        className={localClasses.contactUsContainer}
                    >
                        <Typography className={localClasses.mdBoldText}>
                            Have any question or issues?
                        </Typography>
                        <Typography variant={mdScreen ? 'body1' : 'body2'}>
                            Feel free to reach out to our support with questions
                            or concerns.
                        </Typography>
                        <Button
                            sx={{ alignSelf: 'end' }}
                            href="https://www.hazadapt.com/contact"
                            target="_blank"
                            variant="outlined"
                        >
                            Contact Us{' '}
                            <IoOpenOutline style={{ marginLeft: '0.5rem' }} />
                        </Button>
                    </Stack>
                    {/* Checkbox */}
                    <FormControlLabel
                        sx={{ alignItems: 'flex-start' }}
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                            />
                        }
                        label={
                            <>
                                <Typography
                                    variant={mdScreen ? 'body1' : 'body2'}
                                    fontWeight={500}
                                >
                                    I have read and understand that{' '}
                                    {subscriptionType === 'seat'
                                        ? 'after the last day of this subscription, these seats will no longer be available to my organization on ResiliencePoint.'
                                        : 'after the last day of this subscription, all associated local safety information, including hazard guides, will be removed from HazAdapt.'}
                                </Typography>
                                <Typography
                                    variant={mdScreen ? 'body1' : 'body2'}
                                >
                                    {subscriptionType === 'seat'
                                        ? 'I have removed users from the seats that I plan to cancel, or I plan to do so before the subscription ends.'
                                        : 'I acknowledge that I will no longer have access to this information once my subscription ends.'}
                                </Typography>
                            </>
                        }
                    />
                </Stack>
            )}
            {page === 'success' && (
                <>
                    <div className={localClasses.cancelSuccessContainer}>
                        <Typography
                            variant={mdScreen ? 'body1' : 'body2'}
                            fontWeight={500}
                        >
                            {subscriptionType === 'seat'
                                ? 'Seats Subscription canceled successfully.'
                                : 'Cancellation - Pending'}
                        </Typography>
                        <Typography
                            variant={mdScreen ? 'body1' : 'body2'}
                            fontWeight={500}
                        >
                            A confirmation email has been sent to all
                            organization administrators.
                        </Typography>
                    </div>
                    <Typography
                        variant={mdScreen ? 'body1' : 'body2'}
                        fontWeight={500}
                    >
                        {subscriptionType === 'core'
                            ? "We're sorry to see you go!"
                            : null}
                        Your subscription will remain active until{' '}
                        {subscriptionType === 'core'
                            ? rootSubscriptionEndDate
                            : selectedSubscription?.cancelDate ?? 'N/A'}
                        .
                    </Typography>
                    {subscriptionType === 'core' && (
                        <>
                            <Typography variant="body2">
                                <strong>Please note:</strong> After the final
                                day of your subscription, all associated local
                                safety information, including hazard guides,
                                will be removed from HazAdapt.
                            </Typography>
                            <Typography variant="body2">
                                Please be aware that once your subscription
                                ends, you will no longer have access to delete
                                or make changes to this information.
                            </Typography>
                        </>
                    )}
                </>
            )}
        </>
    )
}

const useLocalStyles = makeStyles()({
    mdBoldText: {
        fontSize: '1rem',
        fontWeight: 500,
        [theme.breakpoints.up('md')]: { fontSize: '1.25rem' },
    },
    contactUsContainer: {
        backgroundColor: colors.grays.BLANC,
        borderRadius: customBorderRadius,
        boxShadow: theme.shadows[4],
        padding: '1rem .5rem 1rem 1.5rem',
        maxWidth: '20rem',
    },
    cancelSuccessContainer: {
        backgroundColor: colors.secondary.HEAT_WAVE,
        borderRadius: customBorderRadius,
        padding: '1rem 1.5rem',
        maxWidth: '40rem',
    },
})
