import { FC, useCallback, useMemo } from 'react'
import { RPPaymentMethod } from '../../lib/entities'
import {
    SimplifiedSeatSubscription,
    useBilling,
} from '../../lib/hooks/useBilling'
import { Dialog } from '../atoms'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { Stack } from '@mui/system'
import { PaymentMethodDetails } from './PaymentMethodDetails'
import { useWindowSizeUp } from '../../lib/utils'
import { SubscriptionStatus } from '@hazadapt-git/public-core-base'

interface DeletePaymentMethodModalProps {
    open?: boolean
    onCancel?(): void
    onConfirmDelete?: React.MouseEventHandler
    paymentMethod?: RPPaymentMethod
}

export const DeletePaymentMethodModal: FC<DeletePaymentMethodModalProps> = ({
    open,
    onCancel,
    onConfirmDelete,
    paymentMethod,
}) => {
    const lgScreens = useWindowSizeUp('lg')
    const { simplifiedSeatSubscriptions } = useBilling()

    const linkedSubscriptions = useMemo(() => {
        return simplifiedSeatSubscriptions.filter(
            (sub) =>
                sub.paymentMethod === paymentMethod?.id &&
                sub.status === SubscriptionStatus.ACTIVE
        )
    }, [paymentMethod?.id, simplifiedSeatSubscriptions])

    const SubscriptionPreview = useCallback(
        (sub: SimplifiedSeatSubscription) => (
            <li key={sub.id}>
                <Typography
                    variant={lgScreens ? 'h4' : 'body1'}
                    fontWeight={500}
                >
                    {sub.seatCount} seat{sub.seatCount === 1 ? '' : 's'} -
                    {sub.status === SubscriptionStatus.PENDING_CANCELLATION
                        ? 'Ending:'
                        : 'Renewal:'}{' '}
                    {sub.renewalDate}
                </Typography>
            </li>
        ),
        [lgScreens]
    )

    if (!paymentMethod) return null

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            title="Delete Payment Method"
            content={
                <Stack gap="1rem">
                    <Typography>
                        Are you sure you want to delete the following payment
                        method from your organization's ResiliencePoint space?
                    </Typography>
                    <PaymentMethodDetails {...paymentMethod} hideDefaultTag />
                    <Typography>You can always add it again later.</Typography>
                    {linkedSubscriptions.length > 0 && (
                        <Stack gap="0.5rem">
                            <Alert severity="warning">
                                This payment method is linked to{' '}
                                {linkedSubscriptions.length} subscription
                                {linkedSubscriptions.length === 1 ? '' : 's'}.
                            </Alert>
                            <ul style={{ margin: 0 }}>
                                {linkedSubscriptions.map(SubscriptionPreview)}
                            </ul>
                        </Stack>
                    )}
                </Stack>
            }
            ctas={[
                <Button
                    key="cancel"
                    onClick={onCancel}
                    variant="outlined"
                    color="secondary"
                >
                    Cancel
                </Button>,
                <Button
                    key="confirm-delete"
                    onClick={onConfirmDelete}
                    variant="contained"
                    color="error"
                >
                    Delete Payment Method
                </Button>,
            ]}
        />
    )
}
