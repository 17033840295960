interface EnvConfig {
    env: 'dev' | 'staging' | 'preview' | 'prod' | null
    apiUrl: string
    publicApiUrl: string
    authUrl: string
    authAPIUrl: string
    pdfUrl: string
    publicAppUriBase: string
    rpUriBase: string
    hazGuideQuickUriBase: string
}

const ENV: { [key: string]: EnvConfig } = {
    dev: {
        env: 'dev',
        apiUrl: 'https://localho.st:5556/rp',
        publicApiUrl: 'https://localho.st:5556/public',
        authUrl: 'https://localho.st:4455',
        authAPIUrl: 'https://localho.st:4000',
        pdfUrl: 'https://atlas.hazadaptlabs.xyz',
        publicAppUriBase: 'https://earth.hazadaptlabs.xyz',
        rpUriBase: 'localho.st',
        hazGuideQuickUriBase: 'https://moon.hazadaptlabs.xyz',
    },
    staging: {
        env: 'staging',
        apiUrl: 'https://triton.hazadaptlabs.xyz/rp',
        publicApiUrl: 'https://triton.hazadaptlabs.xyz/public',
        authUrl: 'https://oberon.hazadaptlabs.xyz',
        authAPIUrl: 'https://auth.hazadaptlabs.xyz',
        pdfUrl: 'https://atlas.hazadaptlabs.xyz',
        publicAppUriBase: 'https://earth.hazadaptlabs.xyz',
        rpUriBase: 'ganymede.hazadaptlabs.xyz',
        hazGuideQuickUriBase: 'https://moon.hazadaptlabs.xyz',
    },
    preview: {
        env: 'preview',
        apiUrl: 'https://resiliencepoint-api.hazadapt.com/rp',
        publicApiUrl: 'https://resiliencepoint-api.hazadapt.com/public',
        authUrl: 'https://id.hazadapt.com',
        authAPIUrl: 'https://iam.hazadapt.com',
        pdfUrl: 'https://cdn.hazadapt.com',
        publicAppUriBase: 'https://public-haz-web-vbeta-2023.hazadapt.com',
        rpUriBase: 'resilience-point-vpreview.hazadapt.com',
        hazGuideQuickUriBase: 'https://haz.guide',
    },
    prod: {
        env: 'prod',
        apiUrl: 'https://resiliencepoint-api.hazadapt.com/rp',
        publicApiUrl: 'https://resiliencepoint-api.hazadapt.com/public',
        authUrl: 'https://id.hazadapt.com',
        authAPIUrl: 'https://iam.hazadapt.com',
        pdfUrl: 'https://cdn.hazadapt.com',
        publicAppUriBase: 'https://app.hazadapt.com',
        rpUriBase: 'rp.hazadapt.com',
        hazGuideQuickUriBase: 'https://haz.guide',
    },
    error: {
        env: null,
        apiUrl: '',
        publicApiUrl: '',
        authUrl: '',
        authAPIUrl: '',
        pdfUrl: '',
        publicAppUriBase: '',
        rpUriBase: '',
        hazGuideQuickUriBase: '',
    },
}

export const getEnvVars = (
    env: string | undefined = process.env.REACT_APP_DEPLOY_ENV
) => {
    if (env === 'dev') {
        return ENV.dev
    } else if (env === 'staging') {
        return ENV.staging
    } else if (env === 'preview') {
        return ENV.preview
    } else if (env === 'production') {
        return ENV.prod
    } else {
        return ENV.error
    }
}

export const inDevEnv = () =>
    process.env.REACT_APP_DEPLOY_ENV === 'dev' ||
    process.env.REACT_APP_DEPLOY_ENV === 'staging'
export const inPreviewEnv = () => process.env.REACT_APP_DEPLOY_ENV === 'preview'
