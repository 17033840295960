import {
    Organization,
    OrganizationWithMembers,
    RPUser,
} from '@hazadapt-git/public-core-base'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface BaseStoreState {
    user?: RPUser | null
    organization?: Organization | null
    fullOrganization?: OrganizationWithMembers | null
    userHasAccess: boolean
    desktopMenuOpen: boolean
}

const INITIAL_BASE_STORE_STATE: BaseStoreState = {
    userHasAccess: true,
    desktopMenuOpen: true,
}

const baseSlice = createSlice({
    name: 'base',
    initialState: INITIAL_BASE_STORE_STATE,
    reducers: {
        setUser: (state, action: PayloadAction<RPUser | null>) => {
            state.user = !!action.payload
                ? { ...action.payload }
                : action.payload
        },
        updateUserHasAccess: (state, action: PayloadAction<boolean>) => {
            state.userHasAccess = action.payload
        },
        updateUser: (state, action: PayloadAction<Partial<RPUser | null>>) => {
            if (state.user) {
                state.user = {
                    ...state.user,
                    ...action.payload,
                }
            }
        },
        updateProfilePictureURI: (state, action: PayloadAction<string>) => {
            if (state.user) {
                state.user = {
                    ...state.user,
                    profile_picture_uri: action.payload,
                }
            }
        },
        setOrganization: (
            state,
            action: PayloadAction<Organization | null>
        ) => {
            state.organization = !!action.payload
                ? { ...action.payload }
                : action.payload
        },
        setFullOrganization: (
            state,
            action: PayloadAction<OrganizationWithMembers | null>
        ) => {
            state.fullOrganization = !!action.payload
                ? { ...action.payload }
                : action.payload
        },
        setDesktopMenuOpen: (state, action: PayloadAction<boolean>) => {
            state.desktopMenuOpen = action.payload
        },
    },
})

export const selectJurisdiction = (state: RootState) =>
    state.base.organization?.jurisdiction
export const {
    setDesktopMenuOpen,
    setUser,
    setOrganization,
    setFullOrganization,
    updateUser,
    updateProfilePictureURI,
    updateUserHasAccess,
} = baseSlice.actions
export default baseSlice.reducer
