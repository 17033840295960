import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, { FC } from 'react'
import RPZenDude from './../../assets/images/RP_zen_dude.svg'
import RPLogoBlack from './../../assets/images/RPLogoB.svg'
import { useWindowSizeUp } from '../../lib/utils'
import { makeStyles } from 'tss-react/mui'
import { OrganizationOverview } from '@hazadapt-git/public-core-base'
import { theme } from '../../lib/styles/universal'
import Link from '@mui/material/Link'

interface SetUpWizardWelcomePageTemplateProps {
    organization: OrganizationOverview
    inviterName: string
    onLogin: React.MouseEventHandler
    onSignUp: React.MouseEventHandler
}

export const SetUpWizardWelcomePageTemplate: FC<
    SetUpWizardWelcomePageTemplateProps
> = (props: SetUpWizardWelcomePageTemplateProps) => {
    const { classes: localClasses } = useLocalStyles()
    const mdScreens = useWindowSizeUp('md')

    return (
        <div className={localClasses.container}>
            <div className={localClasses.mainContent}>
                {mdScreens ? (
                    <img
                        src={RPZenDude}
                        alt="Emergency manager meditating above his desk"
                        style={{
                            width: '100%',
                            maxHeight: 'calc(100vh - 11.5rem)',
                        }}
                    />
                ) : null}
                <div className={localClasses.contents}>
                    <img
                        src={RPLogoBlack}
                        className={localClasses.rpLogo}
                        alt="ResiliencePoint logo"
                    />
                    <img
                        src={props.organization.logo?.url}
                        className={localClasses.orgLogo}
                        alt={`${props.organization.name} logo`}
                    />
                    <Typography variant="h4" align="center" maxWidth="20rem">
                        {`${props.inviterName} has invited you to join ${props.organization.name} on ResilencePoint.`}
                    </Typography>
                    <Button variant="contained" onClick={props.onLogin}>
                        Get Started
                    </Button>
                    <Typography align="center">
                        New to HazAdapt? Create your HazAdapt ID{' '}
                        <Link
                            component="button"
                            onClick={props.onSignUp}
                            sx={{ verticalAlign: 'baseline' }}
                        >
                            here!
                        </Link>
                    </Typography>
                </div>
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        background:
            'linear-gradient(180deg, rgba(0, 0, 87, 0.33) 0%, rgba(255, 255, 255, 0) 100%)',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '1rem',
    },
    mainContent: {
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: '3fr 2fr',
        },
    },
    contents: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        gap: '1.5rem',
    },
    rpLogo: {
        width: 'min(60%, 20rem)',
        height: 'auto',
    },
    orgLogo: {
        height: '10rem',
        width: 'auto',
        maxWidth: 'calc(100% - 2rem)',
        objectFit: 'contain',
    },
    hazadaptID: {
        width: 'min(60%, 8rem)',
        height: 'auto',
    },
})
