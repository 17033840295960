import { FC } from 'react'
import { RPPaymentMethod } from '../../lib/entities'
import { Dialog } from '../atoms'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Stack } from '@mui/system'
import { PaymentMethodDetails } from './PaymentMethodDetails'

interface DeletePaymentMethodModalProps {
    open?: boolean
    onCancel?(): void
    onConfirmUpdate?: React.MouseEventHandler
    paymentMethod?: RPPaymentMethod
}

export const UpdateDefaultPaymentMethodModal: FC<
    DeletePaymentMethodModalProps
> = ({ open, onCancel, onConfirmUpdate, paymentMethod }) => {
    if (!paymentMethod) return null

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            title="Update Default Payment Method"
            content={
                <Stack gap="1rem">
                    <Typography>
                        Are you sure you want to make the following payment
                        method your organization's default payment method?
                    </Typography>
                    <PaymentMethodDetails {...paymentMethod} hideDefaultTag />
                    <Typography>
                        This payment method will be selected by default when
                        making purchases in ResiliencePoint.
                    </Typography>
                </Stack>
            }
            ctas={[
                <Button
                    key="cancel"
                    onClick={onCancel}
                    variant="outlined"
                    color="secondary"
                >
                    Cancel
                </Button>,
                <Button
                    key="confirm-update"
                    onClick={onConfirmUpdate}
                    variant="contained"
                >
                    Confirm
                </Button>,
            ]}
        />
    )
}
