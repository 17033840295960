import {
    BiologicalSex,
    FeaturePackage,
    Gender,
    LanguageCode,
    MapAreaLevel,
    MapInsightTile,
    OrganizationWithMembers,
    PickerItem,
    RPOrganizationOverview,
    RPUser,
    RPUserPermission,
    State,
    SurveyAnswerInsight,
    TrendingHazardDTO,
    ZOOM_THRESHOLDS,
    colors,
} from '@hazadapt-git/public-core-base'
import { subDays } from 'date-fns'
import { IoAdd } from 'react-icons/io5'
import { InsightViewProps } from '../molecules'
import ReactLogo from '../../logo.svg'
import MountainConquerer from '../../assets/images/MountainConquerer.svg'

export const SINGLE_BAR_GRAPH_INSIGHT: InsightViewProps = {
    placement_id: 'single-bar',
    type: 'bar',
    headline:
        'React and Recover are the most commonly viewed hazard stages for Tornado.',
    options: [{ icon: <IoAdd />, label: 'test', onClick: () => {} }],
    orientation: 'horizontal',
    bars: [
        {
            id: 'tornado-prepare',
            value: 3,
            label: 'Prepare',
            color: colors.chips.LIGHTSEA,
        },
        {
            id: 'tornado-react',
            value: 16,
            label: 'React',
            color: colors.chips.FALU,
        },
        {
            id: 'tornado-recover',
            value: 9,
            label: 'Recover',
            color: colors.chips.DODGER,
        },
        {
            id: 'tornado-cat4',
            value: 7,
            label: 'Category 4',
            color: colors.chips.CARIBBEANGREEN,
        },
        {
            id: 'tornado-cat5',
            value: 2,
            label: 'Category 5',
            color: colors.chips.MOSQUE,
        },
        {
            id: 'tornado-cat6',
            value: 8,
            label: 'Category 6',
            color: colors.chips.INDIGO,
        },
    ],
    date_updated: new Date(),
    axes: {
        x: 'Number of times opened',
        y: 'Hazard Stage',
    },
    ctas: [],
}

export const MULTI_BAR_GRAPH_INSIGHT: InsightViewProps = {
    placement_id: 'multi-bar',
    headline: 'Tornado was the most viewed hazard.',
    options: [{ icon: <IoAdd />, label: 'test', onClick: () => {} }],
    type: 'bar',
    orientation: 'horizontal',
    groups: [
        {
            id: 'prepare',
            values: [3, 4, 5],
            name: 'Prepare',
            color: colors.chips.LIGHTSEA,
        },
        {
            id: 'react',
            values: [16, 14, 12],
            name: 'React',
            color: colors.chips.FALU,
        },
        {
            id: 'recover',
            values: [9, 19, 5],
            name: 'Recover',
            color: colors.chips.DODGER,
        },
    ],
    labels: ['Tornado', 'Hurricane', 'Earthquake'],
    date_updated: new Date(),
    axes: {
        x: 'Number of times opened',
        y: 'Hazard',
    },
    stacked: true,
    ctas: [
        { label: 'More Details', href: '/' },
        { label: 'Even More', href: '/' },
    ],
}

export const LINE_FILL_INSIGHT: InsightViewProps = {
    placement_id: 'line-fill',
    type: 'line-fill',
    headline: 'Here are the Prep Check breakdowns.',
    options: [{ icon: <IoAdd />, label: 'test', onClick: () => {} }],
    lines: [
        {
            id: 'tornado-prepare',
            value: 0.25,
            label: 'Coping Actions',
            valueLabel: 'Low',
            color: colors.chips.LIGHTSEA,
        },
        {
            id: 'tornado-react',
            value: 0.65,
            label: 'Emergency Items',
            valueLabel: 'Intermediate',
            color: colors.chips.FALU,
        },
        {
            id: 'tornado-recover',
            value: 0.89,
            label: 'Trusted Help Network',
            valueLabel: 'High',
            color: colors.chips.DODGER,
        },
    ],
    date_updated: new Date(),
    ctas: [],
    demographic_mode: 'residents',
}

export const LINE_GRAPH_INSIGHT: InsightViewProps = {
    placement_id: 'line',
    type: 'line',
    headline: "Here's what hazard activity looks like the last few days.",
    options: [{ icon: <IoAdd />, label: 'test', onClick: () => {} }],
    lines: [
        {
            id: 'tornado',
            values: [3, 4, 5],
            label: 'Tornado',
            color: colors.chips.LIGHTSEA,
        },
        {
            id: 'hurricane',
            values: [16, 14, 12],
            label: 'Hurricane',
            color: colors.chips.FALU,
        },
        {
            id: 'earthquake',
            values: [9, 19, 5],
            label: 'Earthquake',
            color: colors.chips.DODGER,
        },
    ],
    points: [
        {
            timestamp: subDays(new Date(), 7),
            label: subDays(new Date(), 7).getTime(),
        },
        {
            timestamp: subDays(new Date(), 5),
            label: subDays(new Date(), 5).getTime(),
        },
        {
            timestamp: subDays(new Date(), 3),
            label: subDays(new Date(), 3).getTime(),
        },
    ],
    date_updated: new Date(),
    axes: {
        x: 'Date',
        y: 'Number of times opened',
    },
    ctas: [],
}

export const DONUT_CHART_INSIGHT: InsightViewProps = {
    placement_id: 'donut',
    type: 'donut',
    headline:
        'React and Recover are the most commonly viewed hazard stages for Tornado.',
    options: [{ icon: <IoAdd />, label: 'test', onClick: () => {} }],
    chunks: [
        {
            id: 'tornado-prepare',
            value: 3,
            label: 'Prepare',
            color: colors.chips.LIGHTSEA,
        },
        {
            id: 'tornado-react',
            value: 16,
            label: 'React',
            color: colors.chips.FALU,
        },
        {
            id: 'tornado-recover',
            value: 9,
            label: 'Recover',
            color: colors.chips.DODGER,
        },
        {
            id: 'tornado-cat4',
            value: 7,
            label: 'Category 4',
            color: colors.chips.CARIBBEANGREEN,
        },
        {
            id: 'tornado-cat5',
            value: 2,
            label: 'Category 5',
            color: colors.chips.MOSQUE,
        },
        {
            id: 'tornado-cat6',
            value: 8,
            label: 'Category 6',
            color: colors.chips.INDIGO,
        },
    ],
    date_updated: new Date(),
    ctas: [],
}

export const TRENDING_HAZARDS: TrendingHazardDTO[] = [
    {
        id: 1,
        name: 'Wildfire',
        slug: 'Wildfire slug',
        icon: {
            src: ReactLogo,
            alt: 'Wildfire',
        },
        view_count: 20,
        creator: {
            id: 1,
            name: 'Fake Organization',
        },
        local: true,
    },
    {
        id: 2,
        name: 'Tsunami',
        slug: 'Tsunami slug',
        icon: {
            src: ReactLogo,
            alt: 'Tsunami',
        },
        view_count: 20,
        creator: {
            id: 2,
            name: 'Fake Organization',
        },
        local: false,
    },
    {
        id: 3,
        name: 'Another Hazard',
        slug: 'Another Hazard slug',
        icon: {
            src: ReactLogo,
            alt: 'Another Hazard',
        },
        view_count: 20,
        creator: {
            id: 3,
            name: 'Fake Organization',
        },
        local: false,
    },
    {
        id: 4,
        name: 'More Hazards',
        slug: 'More Hazards slug',
        icon: {
            src: ReactLogo,
            alt: 'More Hazards',
        },
        view_count: 20,
        creator: {
            id: 4,
            name: 'Fake Organization',
        },
        local: true,
    },
    {
        id: 5,
        name: 'Very Long Title to Test How Text Acts When The Title Is Unnecessarily Long',
        slug: 'Long Text slug',
        icon: {
            src: ReactLogo,
            alt: 'Long Text',
        },
        view_count: 20,
        creator: {
            id: 5,
            name: 'Fake Organization',
        },
        local: true,
    },
]

export const AREAS: PickerItem<string>[] = [
    {
        label: 'your jurisdiction',
        value: 'jurisdiction',
    },
    {
        label: 'zip code 97007',
        value: '97007',
    },
]

export const MAP_LAYER: MapInsightTile[] = [
    {
        id: '97124',
        label: '97124',
        name: '97124',
        value: 0.5,
        lightening: 25,
        fillColor: colors.primary.CORAL,
    },
    {
        id: '97008',
        label: '97008',
        name: '97008',
        value: 0.4,
        lightening: 30,
        fillColor: `#8C242A`,
    },
]

export const MAP_INSIGHT: InsightViewProps = {
    placement_id: 'map',
    headline: "Here's what app usage looks like in your jurisdiction.",
    options: [{ icon: <IoAdd />, label: 'test', onClick: () => {} }],
    map_config: MAP_LAYER,
    date_updated: new Date(),
    type: 'map',
    defaultCenter: [-122.67648798942062, 45.516178704128436],
    defaultZoom: 10,
    onSwitchColorTheme: () => {},
    ctas: [],
    legend: [],
    minZoom: ZOOM_THRESHOLDS[MapAreaLevel.COUNTY],
    onUpdate: async () => {},
    config: {
        pickers: {
            variant: {
                options: [
                    { label: 'HazAdapt Activity', value: 'app-activity' },
                    {
                        label: 'average Prep Check Scores',
                        value: 'prep-check-scores',
                    },
                ],
                selected_option: 'app-activity',
            },
            theme: {
                options: [
                    { label: 'Green', value: '#60BC97' },
                    { label: 'Blue', value: '#5777FF' },
                    { label: 'Red', value: '#FF414D' },
                ],
                selected_option: '#60BC97',
            },
            mode: {
                options: [
                    { label: 'Residents', value: 'residents' },
                    { label: 'Visitors', value: 'visitors' },
                ],
                selected_option: 'residents',
            },
        },
    },
    onSwitchVariant: () => {},
    onSwitchMode: () => {},
}

export const MULTI_COUNTER_INSIGHT: InsightViewProps = {
    placement_id: 'multi-counter',
    type: 'multi-counter',
    headline: 'These are the most viewed content blocks.',
    options: [{ icon: <IoAdd />, label: 'test', onClick: () => {} }],
    counters: [
        {
            id: 'counter-1',
            counter: 33,
            label: 'Block 1',
            counterText: '33 Views',
            viewConfig: {
                backgroundColor: colors.grays.SMOOTH,
                icon: {
                    src: ReactLogo,
                    alt: 'React logo',
                },
            },
        },
        {
            id: 'counter-2',
            counter: 26,
            label: 'Block 2',
            counterText: '26 Views',
            viewConfig: {
                backgroundColor: colors.grays.SMOOTH,
                icon: {
                    src: ReactLogo,
                    alt: 'React logo',
                },
            },
        },
        {
            id: 'counter-3',
            counter: 19,
            label: 'Block 3',
            counterText: '19 Views',
            viewConfig: {
                backgroundColor: colors.grays.SMOOTH,
                icon: {
                    src: ReactLogo,
                    alt: 'React logo',
                },
            },
        },
        {
            id: 'counter-4',
            counter: 17,
            label: 'Block 4',
            counterText: '17 Views',
            viewConfig: {
                backgroundColor: colors.grays.SMOOTH,
                icon: {
                    src: ReactLogo,
                    alt: 'React logo',
                },
            },
        },
        {
            id: 'counter-5',
            counter: 12,
            label: 'Block 5',
            counterText: '12 Views',
            viewConfig: {
                backgroundColor: colors.grays.SMOOTH,
                icon: {
                    src: ReactLogo,
                    alt: 'React logo',
                },
            },
        },
        {
            id: 'counter-6',
            counter: 8,
            label: 'Block 6',
            counterText: '8 Views',
            viewConfig: {
                backgroundColor: colors.grays.SMOOTH,
                icon: {
                    src: ReactLogo,
                    alt: 'React logo',
                },
            },
        },
        {
            id: 'counter-7',
            counter: 7,
            label: 'Block 7',
            counterText: '8 Views',
            viewConfig: {
                backgroundColor: colors.grays.SMOOTH,
                icon: {
                    src: ReactLogo,
                    alt: 'React logo',
                },
            },
        },
        {
            id: 'counter-8',
            counter: 6,
            label: 'Block 8',
            counterText: '8 Views',
            viewConfig: {
                backgroundColor: colors.grays.SMOOTH,
                icon: {
                    src: ReactLogo,
                    alt: 'React logo',
                },
            },
        },
        {
            id: 'counter-9',
            counter: 5,
            label: 'Block 9',
            counterText: '8 Views',
            viewConfig: {
                backgroundColor: colors.grays.SMOOTH,
                icon: {
                    src: ReactLogo,
                    alt: 'React logo',
                },
            },
        },
    ],
    date_updated: new Date(),
    ctas: [],
}

export const SURVEY_ANSWER_INSIGHT: SurveyAnswerInsight = {
    type: 'survey-answer',
    question_id: 74,
    question_type: 'Checkboxes',
    prev: 'q1-answer-phase-accuracy|35|73',
    next: 'q3-contact-usage|35|75',
    question_name: 'Active Shooter: Emergency Items',
    question: 'Do you have the items?',
    subcopy: '',
    answers: [
        {
            answer: 'Emergency plan',
            bars: [
                {
                    id: 'selected',
                    value: 16,
                    label: '% Who Have This Item',
                    color: '#5777FF',
                },
                {
                    id: 'unselected',
                    value: 9,
                    label: "% Who Don't Have This Item",
                    color: '#000057',
                },
            ],
            axes: {
                x: '',
                y: '',
            },
            map_legend: [],
            orientation: 'vertical',
            placement_id: 'Emergency plan',
            date_updated: new Date(),
            map_config: [],
            weight: 2,
        },
        {
            answer: 'A device that receives emergency alerts',
            bars: [
                {
                    id: 'selected',
                    value: 21,
                    label: '% Who Have This Item',
                    color: '#5777FF',
                },
                {
                    id: 'unselected',
                    value: 4,
                    label: "% Who Don't Have This Item",
                    color: '#000057',
                },
            ],
            axes: {
                x: '',
                y: '',
            },
            map_legend: [],
            orientation: 'vertical',
            placement_id: 'A device that receives emergency alerts',
            date_updated: new Date(),
            map_config: [],
            weight: 2,
        },
        {
            answer: 'First aid kit with wound supplies',
            bars: [
                {
                    id: 'selected',
                    value: 23,
                    label: '% Who Have This Item',
                    color: '#5777FF',
                },
                {
                    id: 'unselected',
                    value: 2,
                    label: "% Who Don't Have This Item",
                    color: '#000057',
                },
            ],
            axes: {
                x: '',
                y: '',
            },
            map_legend: [],
            orientation: 'vertical',
            placement_id: 'First aid kit with wound supplies',
            date_updated: new Date(),
            map_config: [],
            weight: 2,
        },
        {
            answer: 'A phone to call for help',
            bars: [
                {
                    id: 'selected',
                    value: 24,
                    label: '% Who Have This Item',
                    color: '#5777FF',
                },
                {
                    id: 'unselected',
                    value: 1,
                    label: "% Who Don't Have This Item",
                    color: '#000057',
                },
            ],
            axes: {
                x: '',
                y: '',
            },
            map_legend: [],
            orientation: 'vertical',
            placement_id: 'A phone to call for help',
            date_updated: new Date(),
            map_config: [],
            weight: 2,
        },
        {
            answer: 'Furniture and large items for barricading',
            bars: [
                {
                    id: 'selected',
                    value: 24,
                    label: '% Who Have This Item',
                    color: '#5777FF',
                },
                {
                    id: 'unselected',
                    value: 1,
                    label: "% Who Don't Have This Item",
                    color: '#000057',
                },
            ],
            axes: {
                x: '',
                y: '',
            },
            map_legend: [],
            orientation: 'vertical',
            placement_id: 'Furniture and large items for barricading',
            date_updated: new Date(),
            map_config: [],
            weight: 2,
        },
        {
            answer: 'Clean towels',
            bars: [
                {
                    id: 'selected',
                    value: 12,
                    label: '% Who Have This Item',
                    color: '#5777FF',
                },
                {
                    id: 'unselected',
                    value: 13,
                    label: "% Who Don't Have This Item",
                    color: '#000057',
                },
            ],
            axes: {
                x: '',
                y: '',
            },
            map_legend: [],
            orientation: 'vertical',
            placement_id: 'Clean towels',
            date_updated: new Date(),
            map_config: [],
            weight: 1,
        },
        {
            answer: 'Blankets',
            bars: [
                {
                    id: 'selected',
                    value: 14,
                    label: '% Who Have This Item',
                    color: '#5777FF',
                },
                {
                    id: 'unselected',
                    value: 11,
                    label: "% Who Don't Have This Item",
                    color: '#000057',
                },
            ],
            axes: {
                x: '',
                y: '',
            },
            map_legend: [],
            orientation: 'vertical',
            placement_id: 'Blankets',
            date_updated: new Date(),
            map_config: [],
            weight: 1,
        },
        {
            answer: 'Belt, scarf, shoelace, bag-strap to use as tourniquet',
            bars: [
                {
                    id: 'selected',
                    value: 20,
                    label: '% Who Have This Item',
                    color: '#5777FF',
                },
                {
                    id: 'unselected',
                    value: 5,
                    label: "% Who Don't Have This Item",
                    color: '#000057',
                },
            ],
            axes: {
                x: '',
                y: '',
            },
            map_legend: [],
            orientation: 'vertical',
            placement_id:
                'Belt, scarf, shoelace, bag-strap to use as tourniquet',
            date_updated: new Date(),
            map_config: [],
            weight: 1,
        },
        {
            answer: 'Shoes you are able to run in',
            bars: [
                {
                    id: 'selected',
                    value: 24,
                    label: '% Who Have This Item',
                    color: '#5777FF',
                },
                {
                    id: 'unselected',
                    value: 1,
                    label: "% Who Don't Have This Item",
                    color: '#000057',
                },
            ],
            axes: {
                x: '',
                y: '',
            },
            map_legend: [],
            orientation: 'vertical',
            placement_id: 'Shoes you are able to run in',
            date_updated: new Date(),
            map_config: [],
            weight: 1,
        },
    ],
    placement_id: 'q2-item-inventory|35|74',
    date_updated: new Date(),
}

export const QUICK_STAT_INSIGHT_1: InsightViewProps = {
    placement_id: 'quick-stat-1',
    type: 'quick-stat',
    options: [{ icon: <IoAdd />, label: 'test', onClick: () => {} }],
    date_updated: new Date(),
    icon: {
        src: ReactLogo,
        alt: 'React logo',
    },
    color: colors.primary.CERULEAN,
    value: 20,
    ctas: [],
    headline: 'Registered users',
}

export const QUICK_STAT_INSIGHT_2: InsightViewProps = {
    placement_id: 'quick-stat-2',
    type: 'quick-stat',
    options: [{ icon: <IoAdd />, label: 'test', onClick: () => {} }],
    date_updated: new Date(),
    icon: {
        src: MountainConquerer,
        alt: 'Mountain climber',
    },
    color: colors.primary.HELIOTROPE,
    value: 20,
    ctas: [],
    headline: 'Prep Checks completed',
    href: 'https://hazadapt.com',
}

export const QUICK_STAT_INSIGHT_3: InsightViewProps = {
    placement_id: 'quick-stat-3',
    type: 'quick-stat',
    options: [{ icon: <IoAdd />, label: 'test', onClick: () => {} }],
    date_updated: new Date(),
    icon: {
        src: MountainConquerer,
        alt: 'Mountain climber',
    },
    color: colors.primary.HELIOTROPE,
    value: 20,
    ctas: [],
    headline: 'Prep Checks completed',
}

export const QUICK_STAT_INSIGHT_4: InsightViewProps = {
    placement_id: 'quick-stat-4',
    type: 'quick-stat',
    options: [{ icon: <IoAdd />, label: 'test', onClick: () => {} }],
    date_updated: new Date(),
    icon: {
        src: ReactLogo,
        alt: 'React logo',
    },
    color: colors.primary.CERULEAN,
    value: 20,
    ctas: [],
    headline: 'Registered users',
    href: 'https://hazadapt.com',
}

export const QUICK_STAT_INSIGHT_5: InsightViewProps = {
    placement_id: 'quick-stat-5',
    type: 'quick-stat',
    options: [{ icon: <IoAdd />, label: 'test', onClick: () => {} }],
    date_updated: new Date(),
    icon: {
        src: ReactLogo,
        alt: 'React logo',
    },
    color: colors.primary.CERULEAN,
    value: 20,
    ctas: [],
    headline: 'Registered users',
    href: 'https://hazadapt.com',
}

export const INSIGHTS: InsightViewProps[] = [
    SINGLE_BAR_GRAPH_INSIGHT,
    MULTI_BAR_GRAPH_INSIGHT,
    LINE_GRAPH_INSIGHT,
    MAP_INSIGHT,
    DONUT_CHART_INSIGHT,
    LINE_FILL_INSIGHT,
    MULTI_COUNTER_INSIGHT,
    QUICK_STAT_INSIGHT_1,
    QUICK_STAT_INSIGHT_2,
    QUICK_STAT_INSIGHT_3,
    QUICK_STAT_INSIGHT_4,
    QUICK_STAT_INSIGHT_5,
]

export const PRIMARY_RP_USER: RPUser = {
    user_id: 1,
    rp_user_id: 1,
    organization_id: 1,
    title: 'Emergency Preparedness Manager',
    owner: true,
    admin: true,
    gender: null,
    biological_sex: null,
    state: State.OR,
    county: 'Benton',
    preferred_language: LanguageCode.ENGLISH,
    preferred_content_boosters: [],
    zip: '97333',
    tutorials_shown: [],
    dob: subDays(new Date(), 28),
    email: 'team@hazadapt.com',
    phone: '5419918115',
    first_name: 'Mike',
    last_name: 'Bamberger',
    profile_picture_uri: '',
    status: 'active',
    date_created: subDays(new Date(), 28),
    permissions: [RPUserPermission.OWNER, RPUserPermission.ADMIN],
}

export const SECONDARY_RP_USER: RPUser = {
    rp_user_id: 2,
    user_id: 2,
    organization_id: 1,
    title: 'Emergency Preparedness Manager',
    profile_picture_uri:
        'https://media.vogue.fr/photos/5da46eeee189b20008ababeb/2:3/w_2560%2Cc_limit/GettyImages-908311.jpg',
    first_name: 'Rachel',
    last_name: 'Greene',
    status: 'active',
    owner: false,
    admin: true,
    permissions: [],
    gender: Gender.WOMAN,
    state: State.NY,
    biological_sex: BiologicalSex.FEMALE,
    county: 'New York',
    zip: null,
    preferred_language: LanguageCode.ENGLISH,
    preferred_content_boosters: [],
    dob: new Date(),
    tutorials_shown: [],
    email: 'rachel.greene@ny.gov',
    phone: '(555) 123-4567',
    date_created: subDays(new Date(), 28),
}

export const ORGANIZATION: OrganizationWithMembers = {
    id: 1,
    name: 'Primary Org',
    jurisdiction: {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                id: 'portland-test',
                properties: {
                    label: 'Portland',
                    zips: ['97124'],
                },
                geometry: {
                    coordinates: [
                        [
                            [-122.67822495224564, 45.547046112955286],
                            [-122.70073992651693, 45.529738997997754],
                            [-122.70388820681752, 45.51235970220256],
                            [-122.69644681701604, 45.49658000115758],
                            [-122.68337668364657, 45.49203248680752],
                            [-122.66076630694158, 45.49256750755569],
                            [-122.65427894147356, 45.50346744852851],
                            [-122.65027203927292, 45.517841444282936],
                            [-122.65313411227328, 45.52940483139693],
                            [-122.65914446557457, 45.536822862748494],
                            [-122.66725367240993, 45.54484125544113],
                            [-122.67822495224564, 45.547046112955286],
                        ],
                    ],
                    type: 'Polygon',
                },
            },
            {
                type: 'Feature',
                id: 'westside-test',
                properties: {
                    label: 'Westside',
                    zips: ['97008'],
                },
                geometry: {
                    coordinates: [
                        [
                            [-122.70743906020124, 45.515940578157824],
                            [-122.74145665272144, 45.52987472528767],
                            [-122.79681059929408, 45.53495250236571],
                            [-122.84689274143136, 45.509559032406486],
                            [-122.8600722525201, 45.49062189818457],
                            [-122.8600722525201, 45.452728521846154],
                            [-122.83569015700596, 45.42174794761323],
                            [-122.7605669438002, 45.41665995915233],
                            [-122.75134128603783, 45.453190789647834],
                            [-122.69063121215132, 45.479511708202494],
                            [-122.70743906020124, 45.515940578157824],
                        ],
                    ],
                    type: 'Polygon',
                },
            },
        ],
    },
    jurisdiction_name: 'Jurisdiction Name',
    areas: {
        zip: [],
        county: [],
        state: [],
    },
    base_subscription: FeaturePackage.CONTENT_PROVIDER,
    date_created: subDays(new Date(), 28),
    date_updated: subDays(new Date(), 28),
    invites: [],
    members: [PRIMARY_RP_USER, SECONDARY_RP_USER],
    guests: [],
    seats: 6,
    logo: {
        src: 'https://cwi.edu/sites/default/files/styles/news_hero_square_breakpoints_theme_omega_cwi_wide_2x/public/news_ct/image/osu.logo_.jpg?itok=B3An6zR8&timestamp=1571175609',
        alt: 'Oregon State University',
    },
    default_zoom_level: MapAreaLevel.COUNTY,
    status: 'active',
    slug: 'orst',
    visible_hazards: [],
}

export const ORGANIZATION_OVERVIEW: RPOrganizationOverview = {
    id: 1,
    name: 'Primary Org',
    jurisdiction_name: 'Jurisdiction Name',
    logo: {
        src: 'https://cwi.edu/sites/default/files/styles/news_hero_square_breakpoints_theme_omega_cwi_wide_2x/public/news_ct/image/osu.logo_.jpg?itok=B3An6zR8&timestamp=1571175609',
        alt: 'Oregon State University',
    },
    slug: 'orst',
}
