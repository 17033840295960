import axios from 'axios'

import { getEnvVars } from './config'

const { apiUrl, publicApiUrl, authAPIUrl } = getEnvVars()

export const isa = axios.create({
    baseURL: apiUrl,
    headers: {
        'Cache-Control': 'no-cache',
        'X-Client-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    withCredentials: true,
})

export const isaPublic = axios.create({
    baseURL: publicApiUrl,
    headers: {
        'Cache-Control': 'no-cache',
    },
    withCredentials: true,
})

export const authAPI = axios.create({
    baseURL: authAPIUrl,
    headers: {
        Accept: 'application/json',
    },
    withCredentials: true,
})
