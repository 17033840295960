import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import LocalSafetyAnalyticsImage from '../../assets/images/LocalContentAnalytics.svg'
import { useWindowSizeUp } from '../../lib/utils'
import { IoOpenOutline } from 'react-icons/io5'
import { theme } from '../../lib/styles/universal'

interface LocalSafetyInformationPageTemplateProps {
    redirectLink?: string
}

export const LocalSafetyInformationPageTemplate: FC<
    LocalSafetyInformationPageTemplateProps
> = ({ redirectLink }) => {
    const { classes: localClasses } = useLocalStyles()
    const mdScreens = useWindowSizeUp('md')
    return (
        <div className={localClasses.container}>
            <div className={localClasses.content}>
                <Typography
                    variant={mdScreens ? 'h2' : 'h3'}
                    component="h1"
                    display="flex"
                    flexDirection="column"
                >
                    Local Safety Information Insights
                </Typography>
                <Typography fontWeight={500}>
                    See how your Local Safety Information is viewed, clicked,
                    and explored on HazAdapt.
                </Typography>
                <Typography>
                    These analytics are displayed in Mixpanel, and your password
                    is in your{' '}
                    <Typography
                        component="span"
                        fontWeight={500}
                        fontStyle="italic"
                    >
                        Engagement Playbook.
                    </Typography>
                </Typography>
                {redirectLink && (
                    <Button
                        variant="contained"
                        color="primary"
                        href={redirectLink}
                        target="_blank"
                        endIcon={<IoOpenOutline />}
                        className={localClasses.cta}
                    >
                        Open Your Mixpanel Dashboard
                    </Button>
                )}
                <Typography variant="body2">
                    Soon, you'll be able to view these insights directly in
                    ResiliencePoint! 🥳
                </Typography>
            </div>
            <img
                src={LocalSafetyAnalyticsImage}
                alt="A cityscape with green location pins over some builds and grey clouds containing graphs"
                className={localClasses.bannerImage}
            />
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '1rem',
        height: '100%',
        minHeight: 'calc(100dvh - 9.25rem)', // On MW, subtract header and footer height
        [theme.breakpoints.up('md')]: {
            minHeight: '100dvh',
        },
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '1rem',
        padding: '1rem',
    },
    cta: {
        margin: '1rem 0',
    },
    bannerImage: {
        margin: '2rem 0 1rem',
        width: '100%',
    },
})
