import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector, useStore } from 'react-redux'

import BaseReducer from './slices/base'
import ContentReducer from './slices/content'
import PaymentReducer from './slices/payment'

export const reducers = {
    base: BaseReducer,
    content: ContentReducer,
    payment: PaymentReducer,
}

export const store = configureStore({
    reducer: reducers,
    devTools: process.env.REACT_APP_DEPLOY_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store

export const useAppSelector = useSelector.withTypes<RootState>()
export const useAppStore = useStore.withTypes<AppStore>()
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
