import { FC } from 'react'
import { Dialog, TextField } from '../atoms'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {
    colors,
    NewRPUserDTO,
    RPUser,
    RPUserPermission,
} from '@hazadapt-git/public-core-base'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { PermissionsList } from './PermissionsList'
import { NewRPUser } from '../../lib/entities'
import { makeStyles } from 'tss-react/mui'

interface AddTeamMemberModalProps {
    activeUser: RPUser
    newUser: NewRPUserDTO
    updateNewUser(payload: Partial<NewRPUserDTO>): void
    // Dialog props
    open?: boolean
    onClose(): void
    onSubmit(newUser: NewRPUser): void
}

export const AddTeamMemberModal: FC<AddTeamMemberModalProps> = (
    props: AddTeamMemberModalProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const initialValues = {
        first_name: props.newUser.first_name || '',
        last_name: props.newUser.last_name || '',
        email: props.newUser.email || '',
        permissions: props.newUser.permissions || [],
    }
    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .min(1, 'Too short')
            .required('First name is required'),
        last_name: Yup.string()
            .min(1, 'Too short')
            .required('Last name is required'),
        email: Yup.string()
            .strict(true)
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                'Invalid email address'
            )
            .required('Email is required'),
    })

    const handleSubmit = (values: NewRPUserDTO) => {
        props.onSubmit(values)
    }

    const activeUser = props.activeUser

    const closeDialog = () => {
        props.onClose()
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            title="Add Team Member"
            content={
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnChange
                    onSubmit={(values) => {
                        handleSubmit(values)
                    }}
                >
                    {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleBlur,
                    }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className={localClasses.field}
                                >
                                    <Field
                                        as={TextField}
                                        id="first_name"
                                        name="first_name"
                                        type="text"
                                        variant="outlined"
                                        label="First Name"
                                        placeholder="John"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(
                                            touched.first_name &&
                                                errors.first_name
                                        )}
                                    />
                                    <ErrorMessage
                                        name="first_name"
                                        component="div"
                                        className={localClasses.errorMessage}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className={localClasses.field}
                                >
                                    <Field
                                        as={TextField}
                                        id="last_name"
                                        name="last_name"
                                        type="text"
                                        variant="outlined"
                                        label="Last Name"
                                        placeholder="Doe"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(
                                            touched.last_name &&
                                                errors.last_name
                                        )}
                                    />
                                    <ErrorMessage
                                        name="last_name"
                                        component="div"
                                        className={localClasses.errorMessage}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={localClasses.field}
                                >
                                    <Field
                                        as={TextField}
                                        id="email"
                                        name="email"
                                        type="email"
                                        variant="outlined"
                                        label="Email Address"
                                        placeholder="johndoe@example.com"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(
                                            touched.email && errors.email
                                        )}
                                    />
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className={localClasses.errorMessage}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4" mb="1rem">
                                        Permissions
                                    </Typography>
                                    <PermissionsList
                                        activeUser={activeUser}
                                        selectedPermissions={values.permissions}
                                        mode="form"
                                        disabled={
                                            !activeUser.permissions.includes(
                                                RPUserPermission.ADMIN
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        gap: '2rem',
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        key="send-invite"
                                        onSubmit={() => {
                                            handleSubmit(values)
                                        }}
                                    >
                                        Send Invite
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        key="cancel"
                                        type="reset"
                                        onClick={closeDialog}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            }
            ctas={[]}
        />
    )
}

const useLocalStyles = makeStyles()({
    field: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
    errorMessage: {
        color: colors.primary.CORAL,
    },
})
