import {
    HazardOverviewResponse,
    PrepCheckOverviewDTO,
    TrendingHazardDTO,
} from '@hazadapt-git/public-core-base'
import { isa } from '../api'
import { AxiosResponse } from 'axios'
import { store } from '../store'
import {
    setHazards,
    setPrepChecks,
    setTrendingHazards,
} from '../slices/content'

export const getHazardData = async (): Promise<void> => {
    try {
        const response: AxiosResponse<HazardOverviewResponse> = await isa.get(
            `/hazards`
        )
        store.dispatch(setHazards(response.data))
    } catch (err) {
        console.error(err)
    }
}

export const getTrendingHazards = async (): Promise<void> => {
    try {
        const response: AxiosResponse<TrendingHazardDTO[]> = await isa.get(
            `/trending-hazards`
        )
        store.dispatch(setTrendingHazards(response.data))
    } catch (err) {
        console.error(err)
    }
}

export const getPrepCheckData = async (): Promise<void> => {
    try {
        const response: AxiosResponse<PrepCheckOverviewDTO[]> = await isa.get(
            `/prep-checks`
        )
        store.dispatch(setPrepChecks(response.data))
    } catch (err) {
        console.error(err)
    }
}
