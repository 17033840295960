import { RPUser, colors } from '@hazadapt-git/public-core-base'
import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { RPLogo } from '../atoms'
import { ProfileLinkWithAvatar } from '../molecules'
import { NavBarItem } from '../../lib/entities'
import classNames from 'classnames'
import HazAdaptLogo from './../../assets/images/HazAdaptLogoRW.svg'
import SmallHazAdaptLogo from '../../assets/images/HazAdaptAppIcon.svg'
import SmallPRLogo from './../../assets/images/RPInitialsLogoW.svg'
import {
    desktopMenuClosedWidth,
    desktopMenuOpenWidth,
    primaryIconSize,
} from '../../lib/styles/universal'
import { MdMenuOpen } from 'react-icons/md'
import { RootState, useAppSelector } from '../../lib/store'
import { useWindowSizeUp } from '../../lib/utils'
import { NavLink } from 'react-router-dom'

interface DesktopSiteNavProps {
    user?: RPUser
    onToggleMenu(): void
    onLogoPress(): void
    navItems: NavBarItem[]
}

export const DesktopSiteNav: FC<DesktopSiteNavProps> = ({
    user,
    onToggleMenu,
    onLogoPress,
    navItems,
}) => {
    const { classes: localClasses } = useLocalStyles()
    const desktopMenuOpen = useAppSelector(
        (state: RootState) => state.base.desktopMenuOpen
    )
    const lgScreens = useWindowSizeUp('lg')

    const handleNavPress = (e: React.MouseEvent) => {
        e.stopPropagation()
        const linkElement = e.currentTarget as HTMLElement
        if (linkElement.classList.contains('active')) {
            window.dispatchEvent(new Event('page-refresh'))
        }
    }

    const handleLogoPress = (e: React.MouseEvent) => {
        e.stopPropagation()
        onLogoPress()
    }

    const handleLinkPress = (e: React.MouseEvent) => {
        e.stopPropagation()
        window.location.href = 'https://hazadapt.com'
    }

    const handleAvatarPress = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    if (!user) return null

    return (
        <Box
            className={localClasses.container}
            component={'nav'}
            onClick={!desktopMenuOpen ? onToggleMenu : undefined}
            sx={{
                width: desktopMenuOpen
                    ? desktopMenuOpenWidth
                    : desktopMenuClosedWidth,
            }}
        >
            <Box
                className={localClasses.avatarContainer}
                sx={{
                    alignSelf: desktopMenuOpen ? 'flex-end' : 'center',
                }}
                onClick={handleAvatarPress}
            >
                <ProfileLinkWithAvatar
                    src={user.profile_picture_uri ?? undefined}
                    name={user.first_name}
                />
            </Box>
            <Box
                className={localClasses.logoContainer}
                sx={{
                    justifyContent: desktopMenuOpen ? 'start' : 'center',
                    paddingTop: lgScreens ? '2rem' : '.5rem',
                }}
            >
                {desktopMenuOpen ? (
                    <RPLogo
                        onClick={handleLogoPress}
                        className={localClasses.rpLogo}
                    />
                ) : (
                    <Box onClick={handleLogoPress} sx={{ cursor: 'pointer' }}>
                        <img
                            src={SmallPRLogo}
                            alt={'ResiliencePoint Logo'}
                            className={localClasses.smallRPLogo}
                        />
                    </Box>
                )}
            </Box>
            <Box
                className={localClasses.content}
                sx={{
                    gap: 'clamp(0.5rem, 3vh, 2rem)',
                    paddingTop: 'clamp(0.5rem, 3vh, 2rem)',
                }}
            >
                {navItems
                    .filter((item) => !item.hide)
                    .map((item, index) => (
                        <NavLink
                            key={item.label}
                            to={item.path}
                            onClick={(e) => handleNavPress(e)}
                            className={localClasses.navLink}
                        >
                            {({ isActive }) => (
                                <>
                                    {item.icon && (
                                        <Stack>
                                            {item.icon}
                                            <Box
                                                className={
                                                    localClasses.activeItemIndicator
                                                }
                                                sx={{
                                                    backgroundColor:
                                                        !desktopMenuOpen &&
                                                        isActive
                                                            ? colors.grays.BLANC
                                                            : 'inherit',
                                                }}
                                            />
                                        </Stack>
                                    )}
                                    {desktopMenuOpen && (
                                        <Typography
                                            component="div"
                                            className={localClasses.navLinkText}
                                        >
                                            {item.label}
                                            <Box
                                                className={
                                                    localClasses.activeItemIndicator
                                                }
                                                sx={{
                                                    backgroundColor: isActive
                                                        ? colors.grays.BLANC
                                                        : 'inherit',
                                                }}
                                            />
                                        </Typography>
                                    )}
                                </>
                            )}
                        </NavLink>
                    ))}
            </Box>
            <Tooltip
                title={`${desktopMenuOpen ? 'Collapse' : 'Expand'} menu`}
                arrow
            >
                <IconButton
                    onClick={onToggleMenu}
                    aria-label={`${
                        desktopMenuOpen ? 'Collapse' : 'Expand'
                    } menu`}
                    sx={{
                        alignSelf: desktopMenuOpen ? 'flex-end' : 'center',
                        bottom: '3.5rem',
                        marginRight: desktopMenuOpen ? '.5rem' : '',
                        position: 'absolute',
                    }}
                >
                    <MdMenuOpen
                        className={classNames(localClasses.toggleMenuIcon, {
                            [localClasses.rotatedIcon]: !desktopMenuOpen,
                        })}
                    />
                </IconButton>
            </Tooltip>
            <Box className={localClasses.footer} onClick={handleLinkPress}>
                {desktopMenuOpen ? (
                    <>
                        <Typography className={localClasses.footerText}>
                            Powered By
                        </Typography>
                        <img
                            src={HazAdaptLogo}
                            alt={'HazAdapt Logo'}
                            className={localClasses.footerLogo}
                        />
                    </>
                ) : (
                    <img
                        src={SmallHazAdaptLogo}
                        alt={'HazAdapt Logo'}
                        className={localClasses.smallFooterLogo}
                    />
                )}
            </Box>
        </Box>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        backgroundColor: colors.primary.CERULEAN,
        transition: 'all 0.225s ease',
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        height: '100vh',
        left: '0px',
        zIndex: 4,
    },
    avatarContainer: {
        padding: '1rem',
    },
    logoContainer: { display: 'flex', height: '2rem' },
    rpLogo: {
        cursor: 'pointer',
        height: '1.5rem',
        marginLeft: '1rem',
        width: 'auto',
    },
    smallRPLogo: { height: '1.75rem' },
    toggleMenuIcon: {
        color: colors.grays.BLANC,
        size: primaryIconSize,
        transition: 'transform 0.3s ease',
    },
    rotatedIcon: { transform: 'rotate(180deg)' },
    content: {
        alignItems: 'start',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '1rem',
    },
    footer: {
        alignItems: 'center',
        bottom: 0,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: '.5rem',
        position: 'absolute',
        width: '100%',
    },
    footerText: {
        color: colors.grays.BLANC,
        fontSize: '1rem',
        fontWeight: 500,
        paddingBottom: '.25rem',
        paddingRight: '1rem',
        whiteSpace: 'nowrap',
    },
    footerLogo: { height: '2rem', width: 'auto' },
    smallFooterLogo: {
        height: '2.5rem',
        borderRadius: 50,
    },
    navLink: {
        color: colors.grays.BLANC,
        cursor: 'pointer',
        display: 'flex',
        gap: '.5rem',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
    },
    navLinkText: {
        color: 'inherit',
        fontWeight: 500,
    },
    activeItemIndicator: {
        boxSizing: 'border-box',
        height: '1.5px',
        width: '100%',
    },
})
