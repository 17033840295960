import { TZDate } from '@date-fns/tz'
import {
    AdoptionStatsDTO,
    CommunityAdoptionInterval,
    CommunityAdoptionStat,
    ExportFormat,
    Insight,
    InsightGridConfig,
    InsightQueryBase,
    InsightQueryDTO,
    InsightQueryType,
    InsightsDTO,
    LanguageCode,
    SurveyAnswerInsight,
    colors,
    VISITOR_INSIGHTS_SUFFIX,
    SingleBarGraphInsight,
    MultiBarGraphInsight,
} from '@hazadapt-git/public-core-base'
import { AxiosError, AxiosResponse } from 'axios'
import { isa } from '../api'
import { getEnvVars } from '../config'
import {
    InsightViewBaseProps,
    InsightViewProps,
} from '../../components/molecules/InsightView'
import {
    IoAlertCircle,
    IoCheckmarkCircle,
    IoCloudDownloadOutline,
    IoDocument,
    IoImage,
} from 'react-icons/io5'
import { MenuItemProps } from '../entities'
import { formatDateRange, toast, toISOStringWithTimezone } from './misc'
import {
    errorColor,
    infoToastColor,
    primaryIconSize,
} from '../styles/universal'
import { isToday, startOfDay } from 'date-fns'

const { apiUrl } = getEnvVars()

interface InsightQueryRequest extends InsightQueryBase {
    searchParams?: URLSearchParams
    reset?: boolean
    recenter_map?: boolean
    method?: 'GET' | 'POST'
}

interface SingleInsightQueryRequest extends InsightQueryRequest {
    href?: string
}

export const getInsights = async ({
    type = InsightQueryType.CORE,
    searchParams,
    start_date,
    end_date = new Date(),
    hazards = [],
    prep_checks = [],
    zips = [],
    counties = [],
    states = [],
    reset = false,
    insight_ids = [],
    language = LanguageCode.ENGLISH,
    recenter_map = false,
    method = 'GET',
    grid_config,
}: Partial<InsightQueryRequest>): Promise<InsightsDTO> => {
    const url = new URL(`${isa.defaults.baseURL ?? apiUrl}/insights`)
    url.searchParams.set('type', type)
    if (searchParams) {
        Array.from(searchParams.entries()).forEach(([key, val]) => {
            if (key !== 'type') url.searchParams.set(key, val)
        })
    } else {
        if (start_date) {
            url.searchParams.set(
                'start_date',
                toISOStringWithTimezone(startOfDay(new TZDate(start_date)))
            )
        }
        if (isToday(new Date(end_date))) {
            url.searchParams.set(
                'end_date',
                toISOStringWithTimezone(new TZDate())
            )
        } else {
            url.searchParams.set(
                'end_date',
                toISOStringWithTimezone(new TZDate(end_date))
            )
        }
        url.searchParams.set('hazards', hazards.toString())
        url.searchParams.set('prep_checks', prep_checks.toString())
        url.searchParams.set('zips', zips.toString())
        url.searchParams.set(
            'counties',
            counties.map((c) => c.replaceAll(',', '_')).toString()
        )
        url.searchParams.set('states', states.toString())
        if (insight_ids.length > 0) {
            url.searchParams.set('insights', insight_ids.toString())
        }
        url.searchParams.set('language', language)
    }
    if (reset) url.searchParams.set('reset', reset.toString())
    if (recenter_map) url.searchParams.set('recenter', 'true')

    let response: AxiosResponse<InsightsDTO>
    try {
        if (method === 'GET') {
            response = await isa.get(url.toString(), { timeout: 30000 })
        } else {
            response = await isa.post(
                url.toString(),
                { grid_config },
                { timeout: 30000 }
            )
        }
        return response.data
    } catch (err) {
        const error = err as AxiosError
        if (error.response?.status === 504) {
            toast(
                'Unable to get query results. Please try again later.',
                <IoAlertCircle color={errorColor} size={primaryIconSize} />
            )
        }
        throw error
    }
}

export const getSingleInsight = async ({
    type = InsightQueryType.PREP_CHECK_QUESTION,
    searchParams,
    start_date,
    end_date = new Date(),
    hazards = [],
    prep_checks = [],
    zips = [],
    counties = [],
    states = [],
    insight_ids = [],
    reset = false,
    href = '',
    language = LanguageCode.ENGLISH,
    recenter_map = false,
}: Partial<SingleInsightQueryRequest>): Promise<InsightsDTO> => {
    const url = new URL(
        href.includes('query')
            ? `${isa.defaults.baseURL ?? apiUrl}/insights/prep-checks/`
            : `${isa.defaults.baseURL ?? apiUrl}/insights/${href}`
    )

    url.searchParams.set('type', type)
    if (searchParams) {
        Array.from(searchParams.entries()).forEach(([key, val]) => {
            if (key !== 'type') url.searchParams.set(key, val)
        })
    } else {
        if (start_date) {
            url.searchParams.set(
                'start_date',
                toISOStringWithTimezone(startOfDay(new TZDate(start_date)))
            )
        }
        if (isToday(new Date(end_date))) {
            url.searchParams.set(
                'end_date',
                toISOStringWithTimezone(new TZDate())
            )
        } else {
            url.searchParams.set(
                'end_date',
                toISOStringWithTimezone(new TZDate(end_date))
            )
        }
        url.searchParams.set('insight_ids', insight_ids.toString())
        url.searchParams.set('hazards', hazards.toString())
        url.searchParams.set('prep_checks', prep_checks.toString())
        url.searchParams.set('zips', zips.toString())
        url.searchParams.set('counties', counties.toString())
        url.searchParams.set('states', states.toString())
        url.searchParams.set('language', language)
    }
    if (reset) url.searchParams.set('reset', reset.toString())
    if (recenter_map) url.searchParams.set('recenter', 'true')
    try {
        const response: AxiosResponse<InsightsDTO> = await isa.get(
            url.toString(),
            { timeout: 30000 }
        )
        return response.data
    } catch (err) {
        const error = err as AxiosError
        if (error.response?.status === 504) {
            toast(
                'Unable to get query results. Please try again later.',
                <IoAlertCircle color={errorColor} size={primaryIconSize} />
            )
        }
        throw error
    }
}

export const saveQuery = async ({
    type,
    start_date,
    end_date = new Date(),
    hazards = [],
    prep_checks = [],
    zips = [],
    counties = [],
    states = [],
    grid_config = {},
    insight_ids = [],
}: InsightQueryRequest): Promise<void> => {
    const response = await isa.post('/query', {
        type,
        start_date,
        end_date,
        hazards,
        prep_checks,
        zips,
        counties,
        states,
        grid_config,
        insight_ids,
    })

    if (response.status === 201) {
        toast(
            'Query saved.',
            <IoCheckmarkCircle
                color={colors.primary.WAIKATO}
                size={primaryIconSize}
            />
        )
    } else {
        toast(
            'Unable to save query.',
            <IoAlertCircle color={errorColor} size={primaryIconSize} />
        )
    }
}

export const getCommunityAdoptionStat = async (
    stat: CommunityAdoptionStat,
    frequency: CommunityAdoptionInterval,
    zip?: string
): Promise<AdoptionStatsDTO> => {
    const url = new URL(
        `${isa.defaults.baseURL ?? apiUrl}/stats/community-adoption`
    )
    url.searchParams.append('frequency', frequency)
    url.searchParams.append('stat', stat)
    if (zip && zip !== 'jurisdiction') {
        url.searchParams.append('zips', zip)
    }
    const response: AxiosResponse<AdoptionStatsDTO> = await isa.get(
        url.toString()
    )
    return response.data
}

export const getPrepCheckUsageStat = async (
    frequency: CommunityAdoptionInterval,
    area?: string
): Promise<AdoptionStatsDTO> => {
    const url = new URL(
        `${isa.defaults.baseURL ?? apiUrl}/stats/prep-check-usage`
    )
    url.searchParams.append('frequency', frequency)
    if (area) {
        url.searchParams.append('area', area)
    }
    const response: AxiosResponse<AdoptionStatsDTO> = await isa.get(
        url.toString()
    )
    return response.data
}

export const insightOptions = (
    organization_slug: string,
    type: InsightQueryType,
    placement_id: string,
    query?: InsightQueryDTO
): MenuItemProps[] => [
    {
        label: 'Download as PNG',
        icon: <IoImage size={primaryIconSize} />,
        onClick: () =>
            exportInsights(
                type,
                organization_slug,
                query,
                ExportFormat.PNG,
                [placement_id],
                LanguageCode.ENGLISH
            ),
    },
    {
        label: 'Download as PDF',
        icon: <IoDocument size={primaryIconSize} />,
        onClick: () =>
            exportInsights(
                type,
                organization_slug,
                query,
                ExportFormat.PDF,
                [placement_id],
                LanguageCode.ENGLISH
            ),
    },
]

export const refreshInsight = async (
    placement_id: string,
    type: InsightQueryType,
    args: InsightQueryDTO
): Promise<Insight> => {
    const response: AxiosResponse<Insight> = await isa.post(
        `/insights/${placement_id}`,
        {
            ...args,
            start_date: args.start_date
                ? toISOStringWithTimezone(
                      startOfDay(new TZDate(args.start_date))
                  )
                : undefined,
            end_date: args.end_date
                ? toISOStringWithTimezone(new TZDate(args.end_date))
                : undefined,
            type,
        }
    )
    return response.data
}

export const refreshSurveyInsight = async (
    placement_id: string,
    type: InsightQueryType,
    args: InsightQueryDTO
): Promise<SurveyAnswerInsight> => {
    const response: AxiosResponse<SurveyAnswerInsight> = await isa.post(
        `/insights/${placement_id}`,
        {
            ...args,
            start_date: args.start_date
                ? toISOStringWithTimezone(
                      startOfDay(new TZDate(args.start_date))
                  )
                : undefined,
            end_date: args.end_date
                ? toISOStringWithTimezone(new TZDate(args.end_date))
                : undefined,
            type,
        }
    )
    return response.data
}

export const exportInsights = async (
    type: InsightQueryType,
    organization_slug: string,
    query?: InsightQueryDTO,
    format: ExportFormat = ExportFormat.PDF,
    insights: string[] = [],
    language: LanguageCode = LanguageCode.ENGLISH
): Promise<void> => {
    toast(
        'Downloading report...',
        <IoCloudDownloadOutline color={infoToastColor} size={primaryIconSize} />
    )

    const reminder1Timeout = setTimeout(() => {
        toast(
            'Still working. Hang tight.',
            <IoCloudDownloadOutline
                color={infoToastColor}
                size={primaryIconSize}
            />
        )
    }, 5000)
    const reminder2Timeout = setTimeout(() => {
        toast(
            'This should be done soon.',
            <IoCloudDownloadOutline
                color={infoToastColor}
                size={primaryIconSize}
            />
        )
    }, 10000)

    const url = new URL(`${isa.defaults.baseURL ?? apiUrl}/export/insights`)
    if (query) {
        url.searchParams.set('type', query.type)
        url.searchParams.set('format', format)
        if (query.id) url.searchParams.set('query_id', query.id.toString())
        if (query.start_date) {
            url.searchParams.set(
                'start_date',
                toISOStringWithTimezone(
                    startOfDay(new TZDate(query.start_date))
                )
            )
        }
        if (query.end_date) {
            url.searchParams.set(
                'end_date',
                toISOStringWithTimezone(new TZDate(query.end_date))
            )
        }
        if (query.hazards)
            url.searchParams.set('hazards', query.hazards.toString())
        if (query.prep_checks)
            url.searchParams.set('prep_checks', query.prep_checks.toString())
        if (query.zips) url.searchParams.set('zips', query.zips.toString())
        url.searchParams.set('language', language)
        if (insights.length > 0)
            url.searchParams.set('insights', insights.join(','))
    }
    const response: AxiosResponse<Blob> = await isa.post(
        url.toString(),
        { grid_config: query?.grid_config },
        { responseType: 'blob' }
    )
    clearTimeout(reminder1Timeout)
    clearTimeout(reminder2Timeout)

    // create "a" HTML element with href to file & click
    const fileHref = URL.createObjectURL(response.data)
    const link = document.createElement('a')
    const filename = `${organization_slug}-${type}-insight-report-${Date.now()}`
    link.href = fileHref
    link.setAttribute('download', `${filename}.${format}`)
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(fileHref)
}

export const NON_BASIC_INSIGHTS = ['map', 'quick-stat']
export const isBasicInsight = (insight: InsightViewProps) =>
    !NON_BASIC_INSIGHTS.includes(insight.type)

/**
 * Ensure that local changes to insight pickers are preserved when insights are refreshed (since changes have not been saved yet)
 * @param newConfig New insight config dictionary
 * @param prevConfig Previous/existing insight config dictionary
 */
export const persistLocalPickerChangesOnNewConfig = (
    newConfig: InsightGridConfig,
    prevConfig: InsightGridConfig
) => {
    Object.keys(newConfig).forEach((k) => {
        Object.keys(newConfig[k].pickers).forEach((p) => {
            if (
                newConfig[k].pickers[p].options.some(
                    (o) =>
                        o.value === prevConfig[k]?.pickers[p]?.selected_option
                )
            ) {
                newConfig[k].pickers[p].selected_option =
                    prevConfig[k].pickers[p].selected_option
            }
        })
    })
}

/**
 * Build a string representation of the date range of the query
 * @param query the query config object
 * @returns string
 */
export const buildQueryDateRange = (query?: InsightQueryDTO): string =>
    formatDateRange(
        query?.start_date ? new Date(query.start_date) : undefined,
        query?.end_date ? new Date(query.end_date) : undefined
    )

/**
 * Pair resident and visitor insights together (inline change)
 * @param insights the list of insights
 * @returns void
 */
export const pairResidentAndVisitorInsights = (
    insights?: InsightViewProps[]
): void => {
    if (!insights) return
    const insightPairs: Record<string, InsightViewProps[]> = insights.reduce(
        (pairs, ins) => {
            const insightIdBase = ins.placement_id.split(
                VISITOR_INSIGHTS_SUFFIX
            )[0]
            if (!(insightIdBase in pairs)) {
                pairs[insightIdBase] = [ins]
            } else {
                pairs[insightIdBase].push(ins)
            }
            return pairs
        },
        {} as Record<string, InsightViewProps[]>
    )
    Object.values(insightPairs)
        .flat()
        .forEach((ins, i) => {
            insights[i] = ins
        })
}

/**
 * Segment resident and visitor insights separately (inline sort)
 * @param insights the list of insights
 * @returns void
 */
export const segmentResidentAndVisitorInsights = (
    insights?: InsightViewProps[]
): void => {
    if (!insights) return
    insights.sort((a, b) => {
        const aIsVisitor = a.demographic_mode === 'visitors'
        const bIsVisitor = b.demographic_mode === 'visitors'
        if (!aIsVisitor && bIsVisitor) return -1
        if (!bIsVisitor && aIsVisitor) return 1
        return 0
    })
}

export const isSingleBarGraphInsight = (
    props: InsightViewBaseProps
): props is Omit<SingleBarGraphInsight, 'headline'> => {
    return 'bars' in props
}

export const isMultiBarGraphInsight = (
    props: InsightViewBaseProps
): props is Omit<MultiBarGraphInsight, 'headline'> => {
    return 'groups' in props
}

export const insightHasData = (insight: InsightViewProps): boolean => {
    if (insight.type === 'bar') {
        if (isSingleBarGraphInsight(insight)) {
            return insight.bars.some((b) => b.value > 0)
        }
        if (isMultiBarGraphInsight(insight)) {
            return insight.groups.some((g) => g.values.length > 0)
        }
    }
    if (insight.type === 'donut') {
        return insight.chunks.some((ch) => ch.value > 0)
    }
    if (insight.type === 'line') {
        return insight.lines.some((l) => l.values.length > 0)
    }
    if (insight.type === 'line-fill') {
        return insight.lines.length > 0
    }
    if (insight.type === 'map' || insight.type === 'quick-stat') {
        return true
    }
    if (insight.type === 'multi-counter') {
        return insight.counters.length > 0
    }

    return false
}
